import {
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useState,
} from "react";
import { saveEventLog } from "../services/analytics";
import {
  PageLocation,
  buildLocationUrl,
  logPageView,
} from "../services/logging";
import React from "react";

interface LogProviderProps {
  children?: ReactNode;
}

interface LogContextProps {
  logPage: (location: PageLocation) => void;
}

export const LogContext = createContext<LogContextProps>(null);

export const LogProvider: React.FC<LogProviderProps> = ({
  children,
}: LogProviderProps) => {
  //const location = window.location;
  const [, setUrl] = useState("");

  useEffect(() => {
    window.addEventListener("beforeunload", () => saveEventLog("beforeunload"));
    window.document.addEventListener("visibilitychange", function () {
      if (document.visibilityState !== "visible") {
        saveEventLog("visibilitychange");
      }
    });
  }, []);

  const logPage = useCallback((location: PageLocation) => {
    const u = buildLocationUrl(location);
    setUrl((eu) => {
      if (eu !== u) {
        logPageView(u);
      }
      return u;
    });
  }, []);

  useEffect(() => {
    logPage(window.location);
  }, [logPage]);

  return (
    <LogContext.Provider value={{ logPage }}>{children}</LogContext.Provider>
  );
};
