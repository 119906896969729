import React from "react";
import {
  Box,
  Typography,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { DataTypeName } from "../../../../features/data/data-types";
import { CsvRecord } from "../../../../features/import/csv";

export const ImportCsvPreview = ({ records }: { records: CsvRecord[] }) => {
  const [previewRecord, setPreviewRecord] = React.useState<CsvRecord>();
  const [previewIndex, setPreviewIndex] = React.useState(-1);

  const preview = React.useCallback(
    (dir: number) => {
      const index = previewIndex + dir;
      setPreviewIndex(index);
      setPreviewRecord(records[index]);
    },
    [previewIndex, records]
  );

  React.useEffect(() => {
    if (records?.length > 0) {
      setPreviewIndex(0);
      setPreviewRecord(records[0]);
    } else {
      setPreviewRecord(null);
    }
  }, [records]);

  const subRecords = React.useMemo(() => {
    if (!previewRecord) return [];
    const sr = previewRecord.subRecords.map((r) => ({
      ...r,
      fields: r.fields.map((f, i) => ({
        ...f,
        ignore: previewRecord.fields[i].ignore,
      })),
    }));
    return previewRecord.subRecords.length > 0 ||
      previewRecord.fields.some((f) => f.isVariant)
      ? [previewRecord, ...sr]
      : [];
  }, [previewRecord]);

  if (records?.length === 0 || !previewRecord)
    return <Box>No records found</Box>;

  return (
    <Box
      sx={{
        "& td img": {
          maxHeight: "100%",
          maxWidth: 100,
          borderRadius: 0.5,
        },
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6" my={1}>
          Preview
        </Typography>
        <Box>
          <IconButton disabled={previewIndex <= 0} onClick={() => preview(-1)}>
            <ChevronLeftIcon />
          </IconButton>
          <IconButton
            disabled={previewIndex >= records.length - 1}
            onClick={() => preview(1)}
          >
            <ChevronRightIcon />
          </IconButton>
        </Box>
      </Box>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ minWidth: 200 }}>Field</TableCell>
            <TableCell>Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Category</TableCell>
            <TableCell>{previewRecord.categoryName}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Code</TableCell>
            <TableCell>{previewRecord.code} </TableCell>
          </TableRow>
          {previewRecord.fields
            .filter((f) => !f.ignore && !f.isVariant)
            .map((f, i) => (
              <TableRow key={i}>
                <TableCell component="th">{f.name}</TableCell>
                <TableCell>
                  {f.dataType === DataTypeName.Image &&
                    f.value?.indexOf("http") === 0 && (
                      <div>
                        <img src={f.value} alt={f.name} />
                      </div>
                    )}{" "}
                  {f.value}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {subRecords.length > 0 && (
        <Box mt={2}>
          <Typography variant="h6" my={1}>
            Variants
          </Typography>
          <Box
            sx={{
              width: "100%",
              overflowX: "auto",
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  {subRecords[0].fields
                    .filter((f) => !f.ignore && f.isVariant)
                    .map((f, i) => (
                      <TableCell key={i}>{f.name}</TableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {subRecords.map((r) => (
                  <TableRow key={r.index}>
                    {r.fields
                      .filter((f) => !f.ignore && f.isVariant)
                      .map((f, i) => (
                        <TableCell key={i}>
                          {f.dataType === DataTypeName.Image &&
                            f.value?.indexOf("http") === 0 && (
                              <div>
                                <img src={f.value} alt={f.name} />
                              </div>
                            )}
                          {f.value}
                        </TableCell>
                      ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Box>
      )}
    </Box>
  );
};
