export const gridSize = 12;
export const templateGridSize = 1200;

export const gridSizes = Array.from({ length: 12 }, (_, i) => i + 1);

export type DesignAreaSizeProps = {
  w: number;
  h: number;
};

export type BoardViewProps = {
  pageSize?: string;
  productsHor?: number;
  productsVert?: number;
};

export type DesignAreaProps = DesignAreaSizeProps & {
  scaleX: number;
  scaleY: number;
  min: number;
  newSize: number;
  view?: BoardViewProps;
};

export type ColorSchema = {
  a: string;
  b: string;
  c: string;
  d: string;
  n: string;
};

export const colorSchemas: ColorSchema[] = [
  { a: "#FFFFFF", b: "#DDDDDD", c: "#333333", d: "#000000", n: "Monochrome" },
  {
    a: "#000000",
    b: "#777777",
    c: "#CCCCCC",
    d: "#FFFFFF",
    n: "Black & White",
  },
  {
    a: "#E6E2AF",
    b: "#A7A37E",
    c: "#046380",
    d: "#002F2F",
    n: "Beach & Ocean",
  },
  { a: "#FFF0A5", b: "#FFB03B", c: "#B64926", d: "#8E2800", n: "Firenze" },
  { a: "#B9121B", b: "#4C1B1B", c: "#F6E497", d: "#FCFAE1", n: "Cherry Cake" },
  {
    a: "#5C832F",
    b: "#284907",
    c: "#D8CAA8",
    d: "#382513",
    n: "Japanese Garden",
  },
  { a: "#3E606F", b: "#193441", c: "#FCFFF5", d: "#D1DBBD", n: "Neutral Blue" },
  {
    a: "#CCCC9F",
    b: "#9FB4CC",
    c: "#DB4105",
    d: "#FFF8E3",
    n: "Orange on Olive",
  },
  { a: "#10222B", b: "#95AB63", c: "#E2F0D6", d: "#F6FFE0", n: "Zen" },
  { a: "#D9CB9E", b: "#DC3522", c: "#2A2C2B", d: "#1E1E20", n: "Sea Wolf" },
  { a: "#225378", b: "#1695A3", c: "#F3FFE2", d: "#EB7F00", n: "Aspirin C" },
  { a: "#1C1D21", b: "#31353D", c: "#92CDCF", d: "#EEEFF7", n: "Quiet Cry" },
  { a: "#004358", b: "#1F8A70", c: "#BEDB39", d: "#FD7400", n: "Vitamin C" },
  { a: "#595241", b: "#B8AE9C", c: "#FFFFFF", d: "#ACCFCC", n: "Tech Office" },
  {
    a: "#5A1F00",
    b: "#D1570D",
    c: "#FDE792",
    d: "#477725",
    n: "Herbs & Spice",
  },
  {
    a: "#332532",
    b: "#644D52",
    c: "#A49A87",
    d: "#FF974F",
    n: "Times Changing",
  },
  { a: "#703030", b: "#2F343B", c: "#E3CDA4", d: "#C77966", n: "Ralph Lauren" },
  { a: "#89725B", b: "#677E52", c: "#F6E8B1", d: "#B0CC99", n: "Pistachio" },
  {
    a: "#C4D4CB",
    b: "#55665E",
    c: "#542733",
    d: "#E84167",
    n: "Sonic Threshold",
  },
  { a: "#E1E6FA", b: "#C4D7ED", c: "#183152", d: "#375D81", n: "Blues" },
];

export type TargetElement = HTMLElement | SVGElement;

export const roundCoord = (coord: number, scale = 1) => {
  return Math.round(Math.max(coord, 0) / scale);
};

export const getBoardSize = (
  opt: DesignAreaSizeProps | "Portrait" | "Landscape"
): DesignAreaProps => {
  const size =
    typeof opt === "string"
      ? {
          w: opt !== "Landscape" ? 600 : 800,
          h: opt === "Portrait" ? 800 : 600,
        }
      : opt;
  return {
    ...size,
    scaleX: size.w / templateGridSize,
    scaleY: size.h / templateGridSize,
    min: 50,
    newSize: 200,
  };
};
