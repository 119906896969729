import React from "react";
import { useAtom } from "jotai";
import { getServerInfo, loadAccountInfo } from "../accounts/account.service";
import { sessionAtom } from "../accounts/account.state";
import { appState } from "./app.state";
import { getDataChange } from "../products/product.service";

export const useAppState = () => {
  const [session, setSession] = useAtom(sessionAtom);

  const refreshAccountSummary = React.useCallback(
    async (force = false) => {
      if (!appState.info) {
        appState.info = await getServerInfo();
      }
      if (appState.data_dirty || force) {
        const info = await loadAccountInfo();
        const ns = {
          ...session,
          account: { ...session.account, summary: info.summary },
        };
        setSession(ns);
        appState.data_dirty = false;
      }
    },
    [session, setSession]
  );

  const getAppState = React.useCallback(async () => {
    if (!appState.info) {
      appState.info = await getServerInfo();
    }
    return appState;
  }, []);

  const checkDataChange = React.useCallback(async () => {
    const c = await getDataChange();
    if (!appState.data_change) {
      appState.data_change = c;
    } else if (
      c?.timestamp > appState.data_change.timestamp &&
      c.userId !== session.user?.id
    ) {
      return c;
    }
    return null;
  }, [session.user?.id]);

  return { refreshAccountSummary, getAppState, checkDataChange };
};
