import React from "react";

import {
  Paper,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Divider,
  Tooltip,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
} from "@mui/material";

import { DesignerBoardApi, canEditItem } from "../designer.board.api";
import { processItemImages } from "../designer.func";
import { useAtom } from "jotai";
import { IconTypes } from "../../../components/icons.types";
import {
  clipboardAtom,
  addClipboardElement,
} from "../../../features/design/clipboard";
import {
  DesignItemRef,
  BoundRect,
  imagePositions,
} from "../../../features/design/design.types";
import { useEditors } from "../useEditors";
import { CatalogItem } from "../../../features/catalogs/catalogs";
import { CloseButton } from "../../../components/buttons/CloseButton";
import { EMPTY_GUID } from "../../../features/data/data-types";

type DesignItemMenuProps = {
  item?: DesignItemRef;
  bounds: BoundRect;
  api: DesignerBoardApi;
};

export const DesignItemMenu = ({ item, bounds, api }: DesignItemMenuProps) => {
  const [itemAnchor, setItemAnchor] = React.useState<HTMLElement>(null);

  const { openProductListExtEditor, openItemProductEdit } = useEditors();
  const [clipboard, setClipboard] = useAtom(clipboardAtom);
  const [viewOverlay, setViewOverlay] = React.useState(false);

  if (!item?.style || !bounds) return null;

  const { editItem, removeItem, onChange, builder, editedHtml } = api;

  const isTextEdit = editedHtml !== null;
  const canEdit = canEditItem(item);
  const inlineEdit = canEdit || item.info.product;

  const left = bounds.left + (item?.style.left ?? 0) * bounds.zoom + 2;
  const top = bounds.top + (item?.style.top ?? 0) * bounds.zoom - 38;

  const getTemplateName = (templateId?: string) => {
    if (!templateId) return null;
    return api.builder.data.templates.find((t) => t.template.id === templateId)
      ?.template.name;
  };

  return (
    <Paper
      className="unmovable"
      sx={{
        position: "absolute",
        left,
        top: top > 0 ? top : 0,
        zIndex: 1000,
      }}
    >
      {canEdit && (
        <IconButton
          size="small"
          color="primary"
          onClick={() => editItem(item.id)}
        >
          <IconTypes.Edit fontSize="small" />
        </IconButton>
      )}

      {isTextEdit && (
        <IconButton
          size="small"
          color="primary"
          href="https://help.catalogmachine.com/en/articles/844866-product-expressions"
          target="cm-help"
          sx={{ mr: 0.5 }}
        >
          <IconTypes.Question sx={{ fontSize: 14 }} />
        </IconButton>
      )}

      {item.info.product && !isTextEdit && inlineEdit && (
        <IconButton
          size="small"
          color="primary"
          onClick={() => openItemProductEdit(item, onChange)}
        >
          <IconTypes.Product fontSize="small" />
        </IconButton>
      )}

      {item.info.extension && (
        <IconButton
          size="small"
          color="primary"
          onClick={() => openProductListExtEditor(item, builder, onChange)}
        >
          <IconTypes.Settings fontSize="small" />
        </IconButton>
      )}

      {!inlineEdit && (
        <>
          <IconButton
            size="small"
            color="primary"
            onClick={() => {
              onChange({ action: "forward", type: "order-up", item });
              setItemAnchor(null);
            }}
          >
            <IconTypes.AlignTop fontSize="small" />
          </IconButton>
          <IconButton
            size="small"
            color="primary"
            onClick={() => {
              onChange({ action: "backward", type: "order-down", item });
              setItemAnchor(null);
            }}
          >
            <IconTypes.AlignBottom fontSize="small" />
          </IconButton>
        </>
      )}

      {!isTextEdit && (
        <>
          <IconButton
            size="small"
            color="primary"
            onClick={(e) => setItemAnchor(e.currentTarget)}
          >
            <IconTypes.More fontSize="small" />
          </IconButton>
        </>
      )}
      <Menu
        open={!!itemAnchor}
        anchorEl={itemAnchor}
        onClose={() => setItemAnchor(null)}
      >
        <MenuItem
          key="copy"
          onClick={() => {
            const c = addClipboardElement(clipboard, item);
            setClipboard(c);
            setItemAnchor(null);
          }}
        >
          <ListItemIcon>
            <IconTypes.CopyAction fontSize="small" />
          </ListItemIcon>
          <ListItemText>Copy to clipboard</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            removeItem(item.id);
            setItemAnchor(null);
          }}
        >
          <ListItemIcon>
            <IconTypes.DeleteAction fontSize="small" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
          <Typography variant="body2" color="text.secondary" ml={2}>
            Del
          </Typography>
        </MenuItem>
        {inlineEdit && [
          <Divider key="o1" />,
          <MenuItem
            key="o2"
            onClick={() => {
              onChange({ action: "forward", type: "order-up", item });
              setItemAnchor(null);
            }}
          >
            <ListItemIcon>
              <IconTypes.AlignTop fontSize="small" />
            </ListItemIcon>
            <ListItemText>Bring forward</ListItemText>
          </MenuItem>,
          <MenuItem
            key="o3"
            onClick={() => {
              onChange({ action: "backward", type: "order-down", item });
              setItemAnchor(null);
            }}
          >
            <ListItemIcon>
              <IconTypes.AlignBottom fontSize="small" />
            </ListItemIcon>
            <ListItemText>Send to back</ListItemText>
          </MenuItem>,
        ]}
        {item.type === "text" && [
          <Divider key="1" />,
          <Tooltip
            key="scale"
            title="scale text to fit element size"
            placement="right"
          >
            <MenuItem
              onClick={() => {
                item.di.scale = !item.di.scale;
                if (item.di.scale) item.di.exp = false;
                onChange({ type: "refresh", action: "element props" });
                setItemAnchor(null);
              }}
            >
              <ListItemIcon>
                {item.di.scale && <IconTypes.Check fontSize="small" />}
              </ListItemIcon>
              <ListItemText>Scale</ListItemText>
            </MenuItem>
          </Tooltip>,
          <Tooltip
            key="2"
            title="scrollable text for HTML and multi-page for PDF"
            placement="right"
          >
            <MenuItem
              onClick={() => {
                item.di.exp = !item.di.exp;
                if (item.di.exp) item.di.scale = false;
                onChange({ type: "refresh", action: "element props" });
                setItemAnchor(null);
              }}
            >
              <ListItemIcon>
                {item.di.exp && <IconTypes.Check fontSize="small" />}
              </ListItemIcon>
              <ListItemText>Scroll / Expand</ListItemText>
            </MenuItem>
          </Tooltip>,
          <Tooltip
            key="3"
            title="fix position and repeat element for PDF on each page if expanded"
            placement="right"
          >
            <MenuItem
              onClick={() => {
                item.di.fix = !item.di.fix;
                onChange({ type: "refresh", action: "element props" });
                setItemAnchor(null);
              }}
            >
              <ListItemIcon>
                {item.di.fix && <IconTypes.Check fontSize="small" />}
              </ListItemIcon>
              <ListItemText>Fixed (PDF)</ListItemText>
            </MenuItem>
          </Tooltip>,
        ]}
        {item.type === "image" && [
          <Divider key="img-div" />,
          <Typography
            key="img-pos"
            variant="caption"
            sx={{ textAlign: "center" }}
            component="div"
            color="text.secondary"
          >
            Image position
          </Typography>,
          ...imagePositions.map((pos) => (
            <MenuItem
              key={pos}
              onClick={() => {
                item.imgPosition = pos;
                onChange({ action: "image pos", type: "img-position", item });
                setItemAnchor(null);
                setTimeout(() => {
                  processItemImages(".board-item[id='" + item.id + "']");
                });
              }}
            >
              <ListItemIcon>
                {item.imgPosition === pos && (
                  <IconTypes.Check fontSize="small" />
                )}
              </ListItemIcon>
              <ListItemText>{pos}</ListItemText>
            </MenuItem>
          )),
        ]}
        {item.meta === "catalog" &&
          item.info.product && [
            <Divider key="overlay-div" />,
            <Typography
              key="overlay-caption"
              variant="caption"
              component="div"
              color="text.secondary"
              sx={{ textAlign: "center" }}
            >
              Overlay
            </Typography>,
            <MenuItem
              key="overlay"
              onClick={(e) => {
                setViewOverlay(true);
                e.preventDefault();
                e.stopPropagation();
              }}
              className="keep-edit"
            >
              <ListItemIcon
                sx={{ minHeight: 20 }}
                onClick={(e) => {
                  onChange({
                    action: "overlay",
                    type: "update",
                    item,
                    data: {
                      OverrideOverlay: !(item.di as CatalogItem)
                        .OverrideOverlay,
                    },
                  });
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                {(item.di as CatalogItem).OverrideOverlay && (
                  <IconTypes.Check fontSize="small" />
                )}
              </ListItemIcon>
              <ListItemText className="fixed-len-text" sx={{ maxWidth: 150 }}>
                {getTemplateName((item.di as CatalogItem).OverlayTemplateId) ??
                  "Default"}
              </ListItemText>
            </MenuItem>,
          ]}
      </Menu>

      {viewOverlay && (
        <Dialog
          onClose={() => setViewOverlay(false)}
          open={true}
          className="keep-edit"
        >
          <DialogTitle>
            Select Overlay <CloseButton onClose={() => setViewOverlay(false)} />
          </DialogTitle>
          <List sx={{ pt: 0 }}>
            <ListItem disableGutters key={"default"}>
              <ListItemButton
                selected={!(item.di as CatalogItem).OverlayTemplateId}
                onClick={() => {
                  onChange({
                    action: "overlay",
                    type: "update",
                    item,
                    data: { OverlayTemplateId: EMPTY_GUID },
                  });
                  setViewOverlay(false);
                }}
              >
                Default Catalog Overlay
              </ListItemButton>
            </ListItem>
            {api.builder.data.templates.map((t) => (
              <ListItem disableGutters key={t.template.id}>
                <ListItemButton
                  selected={
                    t.template.id === (item.di as CatalogItem).OverlayTemplateId
                  }
                  onClick={() => {
                    onChange({
                      action: "overlay",
                      type: "update",
                      item,
                      data: {
                        OverlayTemplateId: t.template.id,
                        OverrideOverlay: true,
                      },
                    });
                    setViewOverlay(false);
                  }}
                >
                  {t.template.name}
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Dialog>
      )}
    </Paper>
  );
};
