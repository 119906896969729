import React from "react";
import { Box, TextField } from "@mui/material";
import "remirror/styles/all.css";
import "./remirror.scss";
import {
  EditorComponent,
  OnChangeHTML,
  Remirror,
  ThemeProvider,
  useChainedCommands,
  useRemirror,
  useRemirrorContext,
} from "@remirror/react";
import { HtmlEditorMenu } from "./HtmlEditorMenu";
import { HtmlEditorProps, HtmlEditorProvider } from "./editor.context";
import { editorExtensions } from "./editor.extensions";
import { HtmlEditorRef } from "./editor-func";
import { useHtmlEditorContext } from "./useHtmlEditorContext";

export const InnerEditor = (props: HtmlEditorProps) => {
  const context = useHtmlEditorContext();
  return (
    <>
      <OnChangeHTML onChange={context.onChange} />
      {props.children}
    </>
  );
};

export const HtmlEditorBox = ({
  onSourceEdit,
}: {
  onSourceEdit?: (html: string) => void;
}) => {
  const { isSourceEdit, editedHtml, darkBkg, setEditedHtml, fullScreen } =
    useHtmlEditorContext();

  //const { setContent } = useRemirrorContext();

  const [, setEditorWidth] = React.useState(0);

  //const [draftHtml, setDraftHtml] = React.useState<string>(editedHtml ?? "");

  const ref = React.useRef<HTMLDivElement>(null);

  React.useLayoutEffect(() => {
    if (!isSourceEdit) {
      setEditorWidth(ref.current?.offsetWidth ?? 0);
    }
  }, [isSourceEdit, ref.current?.offsetWidth, setEditorWidth]);

  // React.useEffect(() => {
  //   if (isSourceEdit) {
  //     setDraftHtml(editedHtml ?? "");
  //   }
  // }, [isSourceEdit, editedHtml]);

  return (
    <Box
      ref={ref}
      sx={{
        "& .remirror-editor-wrapper": {
          display: isSourceEdit ? "none" : "block",
          "& > div": {
            backgroundColor: darkBkg && !isSourceEdit ? "#333" : null,
          },
          "& .remirror-editor": {
            height: fullScreen ? "calc(100vh - 220px);" : "auto",
          },
        },
      }}
    >
      <ThemeProvider>
        <EditorComponent />
      </ThemeProvider>

      {isSourceEdit && (
        <Box>
          <TextField
            sx={{
              mt: 1,
              width: "100%", // !fullScreen && editorWidth > 0 ? editorWidth : "100%",
              height: fullScreen ? "calc(100vh - 250px);" : 400,
              "& .MuiInputBase-root": {
                bgcolor: "text.primary",
                color: "background.paper",
                p: 0,
                height: "100%",
                minHeight: 100,
              },
              "& textarea": {
                height: "90% !important",
                // minHeight: 100,
                // maxHeight: fullScreen ? "calc(100vh - 250px);" : 400,
                //height: fullScreen ? "calc(100vh - 250px);" : 400,
                overflowY: "auto !important",
                p: 2,
              },
            }}
            fullWidth
            multiline
            value={editedHtml ?? ""}
            onChange={(e) => {
              //setContent(e.target.value);
              //onChange(e.target.value);
              setEditedHtml(e.target.value);
              onSourceEdit?.(e.target.value);
            }}
          />
        </Box>
      )}
    </Box>
  );
};

const ImperativeHandle = React.forwardRef(
  (_: unknown, ref: React.Ref<HtmlEditorRef>) => {
    const { setContent, manager, commands } = useRemirrorContext();

    const replaceContent = (content: string) => {
      manager.view.updateState(manager.createState({ content: content }));
    };

    const chain = useChainedCommands();

    React.useImperativeHandle(ref, () => ({
      setContent,
      replaceContent,
      focus: commands.focus,
      insertText: commands.insertText,
      insertParagraph: commands.insertParagraph,
      chain: chain,
    }));

    return <></>;
  }
);

export const HtmlEditorWrapper = React.forwardRef(
  (props: HtmlEditorProps, ref: React.Ref<HtmlEditorRef>) => {
    const { manager, state } = useRemirror({
      extensions: editorExtensions,
      content: props.value,
      selection: "start",
      stringHandler: "cmCode",
      extraAttributes: [
        {
          identifiers: "nodes",
          attributes: {
            "data-expr-start": { default: null },
            "data-expr-end": { default: null },
          },
        },
        {
          identifiers: ["table", "tableRow", "tableCell", "tableHeaderCell"],
          attributes: { style: { default: null } },
        },
      ],
    });

    return (
      <Remirror manager={manager} initialContent={state}>
        <HtmlEditorProvider {...props}>
          <InnerEditor {...props} />
          <ImperativeHandle ref={ref} />
        </HtmlEditorProvider>
      </Remirror>
    );
  }
);

export const HtmlEditor = (props: HtmlEditorProps) => {
  return (
    <HtmlEditorWrapper {...props}>
      <Box pb={1}>
        <HtmlEditorMenu />
      </Box>

      <HtmlEditorBox onSourceEdit={props.onChange} />
    </HtmlEditorWrapper>
  );
};

ImperativeHandle.displayName = "ImperativeHandle";
HtmlEditorWrapper.displayName = "HtmlEditorWrapper";
