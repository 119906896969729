import { ProductRef } from "../products/product";
import { formatDateStr, parseDate } from "./time";

export const dataPatterns = {
  number: /^\d{0,3}(([,]\d{3})*|(\d)*)([.]\d+)?$/,
  money:
    /^\s*([^\d\s]+)?\s*(\d{1,3}(([,.\s]\d{3})*|(\d)*)([,.]\d*)?)\s*([^\d\s]+)?\s*$/,
  moneyValue: /(\D*)(\d+[.,]?\d*)(\D*)/,
  path: /^(\w|\s|[-.])+$/i,
  permalink: /^(\w|[-])+$/i,
  email: /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,24}$/i,
};

export const dataErrors = {
  money:
    "Invalid money value (Examples: 1000 or $1,000.99 or USD 1000 or 1.500.20 euro)",
  number: "Invalid number",
  permalink: "Permalink can include only letters, numbers and dash.",
};

export const EMPTY_GUID = "00000000-0000-0000-0000-000000000000";

export type EntityType = "products" | "images" | "catalogs" | "pages" | "users";

export enum DataTypeName {
  Text = "Text",
  RichText = "Rich Text",
  Image = "Image",
  Number = "Number",
  Money = "Money",
  Date = "Date",
}

export enum ExtendedDataType {
  Category = "Category",
  Collection = "Collection",
}

export const BaseDataTypeNames = Object.values(DataTypeName) as string[];

export const DefaultImageField = "Image";
export const DefaultPriceField = "Price";

export const DataTypeNames = [
  ...BaseDataTypeNames,
  ...Object.values(DataTypeName),
];

export type BaseDataType =
  | DataTypeName.Text
  | DataTypeName.RichText
  | DataTypeName.Image
  | DataTypeName.Number
  | DataTypeName.Money
  | DataTypeName.Date;

export type DataType =
  | BaseDataType
  | ExtendedDataType.Category
  | ExtendedDataType.Collection;

export type MoneyValue = {
  value: number;
  prefix?: string;
  suffix?: string;
  matched?: boolean;
};

export type SortByType = {
  name: string;
  value: string;
  o?: number;
  sort?: string;
};

export const parseMoney = (value, culture): MoneyValue => {
  if (value) {
    const th = culture === "es" ? "." : ",";
    const dec = culture === "es" ? "," : ".";
    const v = ("" + value).replace(th, "").replace(" ", "");
    const m = v.match(dataPatterns.moneyValue);
    if (m) {
      return {
        value: m.length < 3 || !m[2] ? 0 : +m[2].replace(dec, "."),
        prefix: m.length > 1 ? m[1] : "",
        suffix: m.length > 3 ? m[3] : "",
        matched: true,
      };
    }
  }
  return { value: 0 };
};

export const createPermalink = (text: string) => {
  if (!text) return "";
  return text
    .replace(/[&]/gi, "-and-")
    .replace(/[^a-z0-9]+/gi, "-")
    .replace(/^-*|-*$/g, "")
    .toLowerCase();
};

export const getDifference = (newObj, oldObj) => {
  const diff = Object.entries(newObj).filter((e) => e[1] !== oldObj[e[0]]);
  return diff;
};

export const applyDifference = (target, newObj, oldObj) => {
  getDifference(newObj, oldObj).forEach((d) => (target[d[0]] = d[1]));
};

const inArray = (list: unknown[], i1: number, i2: number) => {
  return (
    i1 !== i2 && i1 >= 0 && i2 >= 0 && i1 < list.length && i2 < list.length
  );
};

export const swapEntries = <T>(list: T[], i1: number, i2: number) => {
  if (!inArray(list, i1, i2)) return list;
  const l = [...list];
  const old = l[i1];
  l[i1] = l[i2];
  l[i2] = old;
  return l;
};

export const moveEntry = <T>(list: T[], i1: number, i2: number) => {
  if (!inArray(list, i1, i2)) return list;
  const l = [...list];
  const e = l[i1];
  l.splice(i1, 1);
  l.splice(i2, 0, e);
  return l;
};

export const equalsCI = (a: string, b: string) =>
  a?.toLowerCase() === b?.toLowerCase();

export const validateValue = (value: string, dataType: DataType) => {
  if (value && dataType === "Money" && !dataPatterns.money.test(value)) {
    return dataErrors.money;
  }
  if (value && dataType === "Number" && !dataPatterns.number.test(value)) {
    return dataErrors.number;
  }
  return null;
};

export const isValidUrl = (string) => {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
};

export const makeBrief = (html: string, len = 30) => {
  if (!html) return "";

  len = len || 30;
  let t = html.length > len + 100 ? html.substring(0, len + 100) : html;
  t = t.replace(/<br>/gm, " ");
  t = t.replace(/<(?:.|\n)*?>/gm, "");
  t = t.replace(/(\s|\n)+/gm, " ");
  t = t.replace(/&nbsp;/gm, " ");

  const i = t.indexOf("<");
  if (i > 0) t = t.substring(0, i);

  return t.length > len ? t.substring(0, len) + "..." : t;
};

export const capitalizeFirstLetter = (text) => {
  if (!text) return "";
  return ("" + text).charAt(0).toUpperCase() + text.slice(1);
};

export const validatePermalink = (p: string) => {
  if (!p) return "Permalink is required";
  if (!dataPatterns.permalink.test(p)) {
    return dataErrors.permalink;
  }
  return null;
};

export const getFieldValue = (
  value: string,
  dataType: string,
  format: boolean
) => {
  if (value && dataType === DataTypeName.Date) {
    return format ? formatDateStr(value) : parseDate(value);
  }
  return value ?? "";
};

export const getProductFieldValue = (
  product: ProductRef,
  field: string,
  format: boolean
) => {
  const f = product?.fieldMap.get(field);
  if (!f) return "";
  return getFieldValue(f.value, f.dataType, format);
};
