import React from "react";
import {
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { IconTypes } from "../../components/icons.types";
import { copyTextToClipboard } from "../../ui/ui-util";

type ShareLinkFieldProps = {
  id: string;
  label: string;
  path: string;
  openIcon?: React.ReactNode;
  openTitle?: string;
  helperText?: string;
  disabled?: boolean;
};

export const ShareLinkField = ({
  id,
  path,
  label,
  openIcon,
  openTitle,
  helperText,
  disabled,
}: ShareLinkFieldProps) => {
  return (
    <FormControl fullWidth>
      <TextField
        id={id}
        label={label}
        value={path}
        helperText={helperText}
        disabled={disabled}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton
                title="Copy the link to clipboard"
                onClick={() => copyTextToClipboard(path, id)}
              >
                <IconTypes.CopyAction />
              </IconButton>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                title={openTitle || "Open this link in the new tab"}
                href={path}
                target="_blank"
              >
                {openIcon ?? <IconTypes.OpenInNew />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </FormControl>
  );
};
