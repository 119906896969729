import { useLocation } from "react-router-dom";
import { useLogging } from "./useLogging";
import useDebouncedEffect from "../ui/useDebounceEffect";

export const PageLogger = () => {
  const location = useLocation();

  const log = useLogging();

  useDebouncedEffect(
    () => {
      log.logPage(location);
    },
    100,
    [location, log]
  );

  return null;
};
