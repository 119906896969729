/* eslint-disable jsx-a11y/no-autofocus */
import {
  Dialog,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  ListSubheader,
} from "@mui/material";
import {
  useCommands,
  useActive,
  DropdownButton,
  CommandMenuItem,
  CommandButton,
} from "@remirror/react";
import { useAtom } from "jotai";
import React from "react";
import { CoreIcon } from "remirror";
import { IframeExtension } from "remirror/extensions";
import { sessionAtom } from "../../../features/accounts/account.state";
import { uiConfigAtom, getFonts } from "../../../features/design/ui.config";
import { ColorPickerPopover } from "../../ColorPicker";

export const FontFamilyButtons = () => {
  const { setFontFamily } = useCommands();
  const active = useActive();

  const [session] = useAtom(sessionAtom);
  const [uiConfig] = useAtom(uiConfigAtom);

  const fonts = React.useMemo(
    () => getFonts(session.account, uiConfig),
    [session.account, uiConfig]
  );

  return (
    <DropdownButton aria-label="Font family" icon="text">
      {fonts.map((f) => {
        return f.IsGroup ? (
          <ListSubheader key={f.Name}>{f.Description}</ListSubheader>
        ) : (
          <CommandMenuItem
            key={f.Name}
            commandName="setFontFamily"
            onSelect={() => setFontFamily(f.Name)}
            enabled={setFontFamily.enabled(f.Name)}
            active={active.fontFamily({ fontFamily: f.Name })}
            label={<span style={{ fontFamily: f.Name }}>{f.Description}</span>}
          />
        );
      })}
    </DropdownButton>
  );
};

const FONT_SIZES = [8, 9, 10, 12, 14, 18, 24, 30, 36, 48, 60, 72, 96];

export const FontSizeButtons = () => {
  const { setFontSize } = useCommands();
  const { fontSize } = useActive();
  return (
    <DropdownButton aria-label="Set font size" icon="fontSize">
      {FONT_SIZES.map((s) => {
        const size = "" + s;
        return (
          <CommandMenuItem
            key={size}
            commandName="setFontSize"
            onSelect={() => setFontSize(size)}
            enabled={setFontSize.enabled(size)}
            active={fontSize({ size })}
            label={size}
            icon={null}
            displayDescription={false}
          />
        );
      })}
    </DropdownButton>
  );
};

export const YoutubeMenu = () => {
  const [video, setVideo] = React.useState<string>();
  const [isEditing, setIsEditing] = React.useState(false);
  const commands = useCommands<IframeExtension>();
  return (
    <>
      <CommandMenuItem
        commandName="insertVideo"
        label="Insert youtube video"
        icon="videoLine"
        enabled={true}
        onSelect={() => setIsEditing(true)}
        displayDescription={false}
      />
      <Dialog
        open={isEditing}
        onClose={() => setIsEditing(false)}
        maxWidth="md"
      >
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Youtube video"
            fullWidth
            variant="standard"
            value={video}
            onChange={(e) => setVideo(e.target.value)}
            helperText="Paste the video id (_P1TQmG9-JQ) or full link (https://www.youtube.com/watch?v=_P1TQmG9-JQ)"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsEditing(false)}>Cancel</Button>
          <Button
            variant="outlined"
            color="primary"
            disabled={!video}
            onClick={() => {
              commands.addYouTubeVideo({ video });
              setIsEditing(false);
            }}
          >
            Insert
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const ColorButton = ({
  name,
  color,
  setColor,
  icon,
  label,
}: {
  name: string;
  color: string;
  setColor: (color) => void;
  icon: CoreIcon | JSX.Element;
  label: string;
}) => {
  const [colorAnchor, setColorAnchor] = React.useState<HTMLElement>(null);
  return (
    <>
      <CommandButton
        commandName={name}
        enabled={true}
        icon={icon}
        onClick={(e) => setColorAnchor(e.currentTarget)}
        onSelect={() => {}}
        label={label}
      />
      <ColorPickerPopover
        color={color}
        setColor={(color) => {
          setColor(color);
          setColorAnchor(null);
        }}
        anchor={colorAnchor}
        onClose={() => setColorAnchor(null)}
      />
    </>
  );
};
