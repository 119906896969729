import React from "react";
import { useAtom } from "jotai";
import { useSnackbar } from "notistack";
import {
  ProductDb,
  refreshCollectionMap,
} from "../../features/products/product";
import {
  modifyProductCollections,
  modifyCollectionItems,
} from "../../features/products/product.service";
import { productDbAtom } from "../../features/products/product.state";
import { logError } from "../../services/logging";

export const useProductOps = () => {
  const [productDb, setProductDb] = useAtom(productDbAtom);
  const { enqueueSnackbar } = useSnackbar();

  const updateProductCollections = React.useCallback(
    async (productId: string, collectionIds: string[], db: ProductDb) => {
      db = db ?? productDb;
      const p = db.productMap.get(productId);
      if (!p) return;

      const newCollectionIds = collectionIds.filter(
        (i) => !p.collections?.some((c) => c.group.id === i)
      );
      const removedCollectionIds =
        p.collections
          ?.filter((c) => !collectionIds.some((i) => c.group.id === i))
          .map((c) => c.group.id) ?? [];

      if (newCollectionIds.length === 0 && removedCollectionIds.length === 0)
        return;

      try {
        await modifyProductCollections(productId, collectionIds);

        newCollectionIds.forEach((id) => {
          const col = db.collectionMap.get(id);
          col.group.items = [...col.group.items, productId];
        });

        removedCollectionIds.forEach((id) => {
          const col = db.collectionMap.get(id);
          col.group.items = col.group.items.filter((i) => i !== productId);
        });

        const productMap = new Map(db.productMap);
        const collectionMap = new Map(db.collectionMap);
        refreshCollectionMap(collectionMap, productMap);
        const db1 = {
          ...db,
          collectionMap,
          productMap,
          version: db.version + 1,
        };
        setProductDb(db1);

        enqueueSnackbar(`Modified ${p.product.name} collections`, {
          variant: "success",
        });
      } catch (e) {
        logError(e);
        enqueueSnackbar(`Error modifying ${p.product.name} collections`, {
          variant: "error",
        });
      }
    },
    [enqueueSnackbar, productDb, setProductDb]
  );

  const updateCollectionItems = React.useCallback(
    async (collectionId: string, productIds: string[], remove: boolean) => {
      const col = productDb.collectionMap.get(collectionId);
      if (!col) return;

      try {
        await modifyCollectionItems(collectionId, productIds, remove);

        if (remove) {
          col.group.items = col.group.items.filter(
            (i) => productIds.indexOf(i) < 0
          );
        } else {
          const newProductIds = productIds.filter(
            (i) => col.group.items.indexOf(i) < 0
          );
          col.group.items = [...col.group.items, ...newProductIds];
        }

        const productMap = new Map(productDb.productMap);
        const collectionMap = new Map(productDb.collectionMap);
        refreshCollectionMap(collectionMap, productMap);
        const db = { ...productDb, collectionMap, productMap };
        setProductDb(db);

        enqueueSnackbar(`Modified ${col.group.name} collection products`, {
          variant: "success",
        });
      } catch (e) {
        logError(e);
        enqueueSnackbar(`Error modifying ${col.group.name} collection`, {
          variant: "error",
        });
      }
    },
    [enqueueSnackbar, productDb, setProductDb]
  );

  return { updateCollectionItems, updateProductCollections };
};
