import { v4 as uuidv4 } from "uuid";
import { positionElement } from "../../containers/designer/designer.func";
import { EMPTY_GUID, DefaultImageField } from "../data/data-types";
import { generateNewEntityName } from "../data/entity";
import {
  TemplateItem,
  TemplateItemRef,
  DesignItem,
  setRotationStyle,
  imagePositions,
  DesignItemRef,
  orderDesignItem,
  removeDesignItem,
} from "../design/design.types";
import { gridSize, templateGridSize, BoardViewProps } from "../design/designer";
import { Template, TemplateRef } from "../templates/templates";
import { CatalogBuilder } from "./catalog.builder";
import {
  syncItemAttrs,
  InitTemplateItemArgs,
  UpdateItemAtrs,
  CreateItemAttrs,
} from "./catalog.design";
import { getTemplateImage, getTemplateImageSrc } from "./catalog.func";
import { getBgStyle, initBg } from "./catalog.style";
import { CatalogItemRef } from "./catalogs";

export const createNewTemplate = (builder: CatalogBuilder): TemplateRef => {
  return {
    template: {
      id: EMPTY_GUID,
      name: generateNewEntityName(
        builder.data.templates,
        "Card",
        (t) => t.template.name
      ),
      width: gridSize,
      height: gridSize,
      isDefault: false,
      isDefaultDetails: false,
      isDefaultList: false,
      body: "[]",
    },
  };
};

const newTemplateItem = (ti: TemplateItem) => {
  return syncItemAttrs<TemplateItemRef>({
    id: uuidv4(),
    version: 0,
    di: ti,
    info: {},
    meta: "template",
  });
};

export const parseTemplateItems = (json: string) => {
  const items = (JSON.parse(json) as TemplateItem[])
    .sort((a, b) => (a.z < b.z ? -1 : 1))
    .map(newTemplateItem);
  return items;
};

export const getTemplateItems = (template: Template) => {
  return parseTemplateItems(template.body || "[]");
};

export const normalizeTemplate = (item: DesignItem, template: TemplateRef) => {
  if (!template) return;
  item.w = (template.template.width * templateGridSize) / gridSize;
  item.h = (template.template.height * templateGridSize) / gridSize;
  if (item.w + item.x > templateGridSize) item.x = templateGridSize - item.w;
  if (item.h + item.y > templateGridSize) item.y = templateGridSize - item.h;
};

export const normalizeTemplateSize = (
  builder: CatalogBuilder,
  item: CatalogItemRef,
  partial: Partial<TemplateItem>
) => {
  if (partial.w && partial.h) {
    const kw =
      (item.info.template.template.width *
        (builder.board.view.productsHor ?? 1)) /
      gridSize;
    const kh =
      (item.info.template.template.height *
        (builder.board.view.productsVert ?? 1)) /
      gridSize;
    return {
      ...partial,
      w: partial.w * kw,
      h: partial.h * kh,
      x: partial.x * kw,
      y: partial.y * kh,
    };
  }
  return partial;
};

export const resizeToGrid = (item: CatalogItemRef, view: BoardViewProps) => {
  item.di.w = templateGridSize / (view?.productsHor ?? 1);
  item.di.h = templateGridSize / (view.productsVert ?? 1);
};

export const adjustTemplateItems = (
  item: CatalogItemRef,
  w: number,
  h: number
) => {
  item.info?.templateItems.forEach((ti) => {
    const kw = w / item.info.template.template.width;
    const kh = h / item.info.template.template.height;
    ti.di = {
      ...ti.di,
      x: Math.round(ti.di.x * kw),
      y: Math.round(ti.di.y * kh),
      w: Math.round(ti.di.w * kw),
      h: Math.round(ti.di.h * kh),
    };
  });
};

export const initTemplateItem = (
  builder: CatalogBuilder,
  ti: TemplateItemRef,
  args: InitTemplateItemArgs
) => {
  const item = args.parent;
  const info = { ...ti.info };
  if (item.info.product && ti.di.imgMode !== "image" && ti.type !== "bg")
    info.product = item.info.product;
  if (ti.type === "text") {
    info.content = builder.evaluator.getContent(ti.di.text, item.info.product);
    //info.briefContent = makeBrief(info.content);
  }
  if (ti.type === "image") {
    info.src = getTemplateImage(
      builder.data.config.imageStorageUrl,
      ti,
      item.info.product,
      !args?.useOriginal,
      item.version
    );
    info.path = getTemplateImageSrc(ti, item.info.product);
    info.imageField =
      ti.di.imgMode === "field" ? ti.di.src || DefaultImageField : null;
  }
  ti.info = info;

  updateTemplateItemStyle(builder, ti, args);

  return syncItemAttrs(ti);
};

export const updateTemplateItemStyle = (
  builder: CatalogBuilder,
  ti: TemplateItemRef,
  args: InitTemplateItemArgs
) => {
  const item = args.parent;
  const dimensions = args.dimensions ?? builder.getActivePage()?.dimensions;

  if (!item?.info.template || !dimensions) return;

  const kw =
    (item.di.w / templateGridSize) *
    (gridSize / item.info.template.template.width);
  const kh =
    (item.di.h / templateGridSize) *
    (gridSize / item.info.template.template.height);

  ti.style = {
    left: Math.round(ti.di.x * dimensions.scaleX * kw),
    top: Math.round(ti.di.y * dimensions.scaleY * kh),
    width: Math.round(ti.di.w * dimensions.scaleX * kw),
    height: Math.round(ti.di.h * dimensions.scaleY * kh),
    zIndex: ti.di.z,
  };
  setRotationStyle(ti);
  if (ti.type === "icon") {
    ti.style.color = ti.di.Color;
    ti.style.fontSize = Math.min(ti.style.width, ti.style.height) + "px";
  }
  if (ti.type === "bg") {
    ti.style = {
      ...ti.style,
      ...getBgStyle(ti, 0, builder),
    };
  }

  //setTextStyle(ti, builder);
};

export const updateTemplateItemContent = (
  ti: TemplateItem,
  attrs: UpdateItemAtrs | CreateItemAttrs
) => {
  const type = attrs.type ?? ti.type;
  if (type === "product-image" || type === "image") {
    ti.imgMode = type === "image" ? "image" : "field";
    ti.src = attrs.content;
    ti.position = ti.position ?? imagePositions[0];
    ti.type = "image";
  } else {
    ti.text = attrs.content;
  }
};

export const createTemplateItem = (
  builder: CatalogBuilder,
  attrs: CreateItemAttrs & InitTemplateItemArgs
) => {
  const { type, x, y, pos } = attrs;

  const w = attrs.w || builder.board.newSize;
  const h = attrs.h || builder.board.newSize;

  const d = attrs.dimensions ?? builder.getActivePage().dimensions;

  const kw = attrs.parent.info.template.template.width / gridSize;
  const kh = attrs.parent.info.template.template.height / gridSize;

  const ti: TemplateItem = {
    type,
    Color: attrs.color,
    w: Math.floor(
      ((w > builder.board.min ? w : builder.board.min) / (d.scaleX ?? 1)) * kw
    ),
    h: Math.floor(
      ((h > builder.board.min ? h : builder.board.min) / (d.scaleY ?? 1)) * kh
    ),
    ...attrs.clone,
    z: 1,
  };

  if (attrs.type === "bg" && !attrs.clone) {
    initBg(ti);
  }

  if (pos === "none") {
    positionElement(ti, builder.getActivePage());
  } else {
    ti.x = Math.floor((x >= 0 ? x : 0) / (d.scaleX ?? 1)) * kw;
    ti.y = Math.floor((y >= 0 ? y : 0) / (d.scaleY ?? 1)) * kh;
  }

  if (ti.w + ti.x > templateGridSize) ti.x = templateGridSize - ti.w;
  if (ti.h + ti.y > templateGridSize) ti.y = templateGridSize - ti.h;

  if (!attrs.clone) updateTemplateItemContent(ti, attrs);

  const item = newTemplateItem(ti);

  attrs.parent.info.templateItems.push(item);
  orderTemplateItem(attrs.parent, item, type !== "bg");
  initTemplateItem(builder, item, attrs);

  return item;
};

export const updateTemplateItems = (
  builder: CatalogBuilder,
  args: InitTemplateItemArgs
) => {
  args.parent.info?.templateItems.forEach((ti) => {
    initTemplateItem(builder, ti, args);
  });
};

export const initTemplateItems = (
  builder: CatalogBuilder,
  args: InitTemplateItemArgs
) => {
  const item = args.parent;
  if (!args?.soft || !item.info.templateItems) {
    item.info.templateItems = item.info.template?.template
      ? getTemplateItems(item.info.template.template)
      : [];
  }
  updateTemplateItems(builder, args);
};

export const updateTemplateItemsStyle = (
  builder: CatalogBuilder,
  args: InitTemplateItemArgs
) => {
  //const d = getDimensions(item.ci.PageId, builder);

  if (!args.parent?.info?.templateItems) return;

  args.parent.info.templateItems.forEach((i) => {
    updateTemplateItemStyle(builder, i, args);
  });
};

export const orderTemplateItem = (
  parent: DesignItemRef,
  item: TemplateItemRef,
  isFront?: boolean,
  withStyle?: boolean
) => {
  if (!item) return;

  parent.info.templateItems = orderDesignItem(
    parent.info.templateItems,
    item,
    isFront,
    withStyle
  );
};

export const removeTemplateItem = (
  parent: CatalogItemRef,
  item: TemplateItemRef
) => {
  if (!item) return;
  parent.info.templateItems = removeDesignItem(parent.info.templateItems, item);
};
