import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { isLoggedIn, isSuperuser } from "../features/accounts/auth.service";
import { Permission } from "../features/accounts/account";
import { useAuth } from "../features/accounts/useAuth";
import { Page } from "../containers/layouts/Page";
import { Typography } from "@mui/material";

interface AuthGuardProps {
  children?: React.ReactNode;
  su?: boolean;
  permission?: Permission;
}

const AuthGuard: React.FC<AuthGuardProps> = ({
  children,
  su,
  permission,
}: AuthGuardProps) => {
  const location = useLocation();
  const { hasPermission } = useAuth();
  if (!isLoggedIn()) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  if (su && !isSuperuser()) {
    return <Navigate to="/" />;
  }
  if (!hasPermission(permission)) {
    //return <Navigate to="/" />;
    return (
      <Page title="Access Denied">
        <Typography>
          You do not have the necessary permissions to access this page.
        </Typography>
      </Page>
    );
  }
  return <>{children}</>;
};

export default AuthGuard;
