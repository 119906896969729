import React from "react";
import {
  Box,
  Checkbox,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";

import { grey } from "@mui/material/colors";
import clsx from "clsx";
import { IconTypes } from "../../components/icons.types";
import { downloadFile } from "../../features/data/file-util";
import { formatDateStr } from "../../features/data/time";
import { BlobImage } from "../../features/images/image";
import { useImages } from "../../features/images/image-func";
import { ImageScroll } from "./ImageScroll";
import { ImageViewOptions } from "./image-components";
import { OpenImageModalFunc } from "./modals/ImageModal";

interface ImageBrowserParams {
  openImageModal: OpenImageModalFunc;
  checked?: Map<string, BlobImage>;
  onCheck?: (image: BlobImage, checked: boolean) => void;
  selectOnly?: boolean;
  onSelect?: (image: BlobImage) => void;
  view: ImageViewOptions;
}

export const ImageBrowser = ({
  view,
  openImageModal,
  onSelect,
  selectOnly,
  checked,
  onCheck,
}: ImageBrowserParams) => {
  const [image, setImage] = React.useState<BlobImage>();
  const [imgAnchorEl, setImgAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  const { openImageEditor } = useImages();

  const handleImgClick = (el, img) => {
    setImgAnchorEl(el);
    setImage(img);
  };

  const renderImages = React.useCallback(
    (images: BlobImage[]) => {
      return images.map((i) => (
        <div
          key={i.path}
          className={clsx("img-item", {
            checked: checked?.get(i.path),
          })}
        >
          <Box
            className="img-area"
            onClick={() => {
              if (selectOnly) {
                onSelect?.(i);
              } else {
                setImage(i);
                openImageModal({ path: i.path }, "view");
              }
            }}
          >
            <img
              src={i.thumb}
              alt=""
              onError={(e) => {
                const img = e.target as HTMLImageElement;
                img.alt = " Load error";
              }}
            />
          </Box>
          {onCheck && (
            <div className="img-check">
              <Checkbox
                checked={!!checked.get(i.path)}
                onChange={(e) => {
                  onCheck(i, e.target.checked);
                }}
              />
            </div>
          )}
          <Box className="bar">
            <div className="caption">
              <div className="title">{view.folder ? i.name : i.path}</div>
              <div className="info">
                <span className="size">
                  {i.size > 0 ? (i.size / 1024).toFixed(0) + " KB" : "N/A "}
                </span>
                <span className="ext">
                  {i.ext && i.size > 0 ? i.ext.toUpperCase() : " "}
                </span>
                <span className="date">{formatDateStr(i.modified)}</span>
              </div>
            </div>
            {!selectOnly && (
              <IconButton
                aria-label="more"
                onClick={(event) => handleImgClick(event.currentTarget, i)}
                size="small"
              >
                <IconTypes.More fontSize="small" />
              </IconButton>
            )}
          </Box>
        </div>
      ));
    },
    [checked, onCheck, onSelect, openImageModal, selectOnly, view.folder]
  );

  return (
    <Box
      sx={{
        p: 1,
        height: "100%",
        overflowY: "auto",
        "& .img-item": {
          width: [120, 120, 160, 240],
          //height: [150, 240],
          m: 2,
          position: "relative",
          "& .img-area": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            py: "4px",
            height: 160,
            "& img": {
              maxWidth: "100%",
              maxHeight: "100%",
            },
            cursor: "pointer",
          },

          "& .img-check": {
            position: "absolute",
            top: 0,
            left: 0,
            display: "none",
            "& .MuiCheckbox-root": {
              padding: "3px",
            },
          },
          "& .bar": {
            display: "flex",
            alignItems: "center",
            width: "100%",
            py: 1,
            "& .caption": {
              width: selectOnly ? "100%" : [120, 120, 160, 190],
              pl: 1,
              "& .title": {
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                fontSize: 14,
                flexGrow: 1,
              },
              "& .info": {
                fontSize: 10,
                color: grey[500],
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                "& .ext": { mx: 1 },
                "& .date": {
                  //ml: "auto",
                  mr: 0.5,
                },
              },
            },
            "& button": {
              //color: grey[100],
              marginLeft: "auto",
            },
          },
          "&:hover": {
            "& .img-check": {
              display: "block",
            },
          },
          "&.checked": {
            background: "rgba(0, 0, 0, 0.1)",
            "& .img-check": {
              display: "block",
            },
            "& .img-area": {
              "& img": {
                maxWidth: "90%",
                maxHeight: "90%",
              },
            },
          },
        },
      }}
    >
      <ImageScroll view={view} renderImages={renderImages} />
      {!selectOnly && (
        <Menu
          anchorEl={imgAnchorEl}
          open={!!imgAnchorEl}
          onClose={() => setImgAnchorEl(null)}
        >
          <MenuItem
            onClick={() => {
              setImgAnchorEl(null);
              openImageEditor(image.path);
            }}
          >
            <ListItemIcon>
              <IconTypes.Edit fontSize="small" />
            </ListItemIcon>
            <ListItemText>Edit</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              setImgAnchorEl(null);
              downloadFile(image.url, image.path);
            }}
          >
            <ListItemIcon>
              <IconTypes.Download fontSize="small" />
            </ListItemIcon>
            <ListItemText>Download</ListItemText>
          </MenuItem>
        </Menu>
      )}
    </Box>
  );
};
