import React from "react";

import { Box, Typography } from "@mui/material";

import { ProductFilterBox } from "./ProductFilterBox";
import FixedPanel from "../../../components/FixedPanel";
import { ProductRef } from "../../../features/products/product";
import { ProductFilter } from "../../../features/products/product-func";
import { ListAction, ProductList } from "./ProductList";
import { productDbAtom } from "../../../features/products/product.state";
import { useAtom } from "jotai";

type ProductPanelProps = {
  actions?: ListAction[];
  onClick?: (ref: ProductRef) => void;
  hint: string;
};

export const ProductPanel = ({ actions, onClick, hint }: ProductPanelProps) => {
  const [filter, setFilter] = React.useState<ProductFilter>({});
  const [productDb] = useAtom(productDbAtom);
  return (
    <Box sx={{ height: "100%" }}>
      {productDb.productMap.size > 0 && (
        <ProductFilterBox filter={filter} setFilter={setFilter} />
      )}
      <Typography variant="caption" sx={{ px: 1, mb: 1 }}>
        {hint}
      </Typography>
      <FixedPanel breakpoint="lg">
        <ProductList actions={actions} filter={filter} onClick={onClick} />
      </FixedPanel>
    </Box>
  );
};
