import { Box, Slider, Typography } from "@mui/material";
import React from "react";
import { CatalogBuilder } from "../../../features/catalogs/catalog.builder";

type Props = {
  zoom: number;
  setZoom: (v: number) => void;
  builder: CatalogBuilder;
  boardId: string;
};

export const PageZoom = ({ builder, boardId, zoom, setZoom }: Props) => {
  const [initiatedZoom, setInitiatedZoom] = React.useState(false);

  const adjustBoardZoom = React.useCallback(() => {
    if (!builder?.ready) return;
    const boardRect = document
      .getElementById(boardId + "-board-panel")
      ?.getBoundingClientRect();
    if (boardRect) {
      const xr = boardRect.width / (builder.board.w + 40);
      const yr = boardRect.height / (builder.board.h + 40);
      const zr = Math.floor(Math.min(xr, yr) * 20) * 5;
      if (zr > 50 && zr < 200) setZoom(zr);
      setInitiatedZoom(true);
    }
  }, [builder?.board.h, builder?.board.w, builder?.ready, setZoom, boardId]);

  React.useEffect(() => {
    if (initiatedZoom) return;
    setTimeout(() => adjustBoardZoom(), 100);
  }, [adjustBoardZoom, initiatedZoom]);

  React.useEffect(() => {
    window.addEventListener("resize", adjustBoardZoom);
    return () => window.removeEventListener("resize", adjustBoardZoom);
  }, [adjustBoardZoom]);

  return (
    <Box
      sx={{
        minWidth: 150,
        display: "flex",
        gap: 1,
        alignItems: "center",
      }}
    >
      <Slider
        value={zoom}
        onChange={(_e, v) => setZoom(v as number)}
        min={25}
        max={200}
        step={5}
      />
      <Typography variant="caption" sx={{ ml: 1 }}>
        {zoom}%
      </Typography>
    </Box>
  );
};
