import { Box } from "@mui/material";
import React, { FC } from "react";
import { Outlet } from "react-router-dom";
import { useAtom } from "jotai";
import ErrorBoundary from "../../contexts/ErrorBoundary";
import { uiAtom } from "../../features/state/uiState";
import useIsMobile from "../../ui/useIsMobile";
import NavBar from "./NavBar";
import "./layout.scss";

const Layout: FC = () => {
  const [uiSettings, setUiSettings] = useAtom(uiAtom);

  const isMobile = useIsMobile();
  React.useEffect(() => {
    if (isMobile && !uiSettings.navBarClosed) {
      setUiSettings({ ...uiSettings, navBarClosed: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      className="layout"
      // sx={{
      //   display: "flex",
      //   height: "100%",
      //   width: "100vw",
      //   overflowY: "hidden",
      //   overflowX: "hidden",
      // }}
    >
      <NavBar
        onNavBarChange={() => {
          setUiSettings({
            ...uiSettings,
            navBarClosed: !uiSettings.navBarClosed,
          });
        }}
        openNavBar={!uiSettings.navBarClosed}
      />
      <Box
        className="layout-container"
        // sx={{
        //   display: "flex",
        //   //flex: "1 1 auto",
        //   // overflowX: "auto",
        //   // overflowY: "hidden",

        //   flex: 1,
        //   overflowX: "auto",
        // }}
      >
        <Box
          className="layout-content"
          // sx={{
          //   flex: "1 1 auto",
          //   height: "100%",
          //   minWidth: 400,
          //   //width: 600,
          // }}
        >
          <ErrorBoundary>
            <Outlet />
          </ErrorBoundary>
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;
