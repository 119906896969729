import { trackEvent } from "../../services/analytics";
import apiClient, {
  OperationResult,
  BaseOperationResult,
} from "../../services/apiClient";
import {
  BlobImage,
  ImageDb,
  ImageDbRaw,
  ROOT_FOLDER,
  parseImagePath,
  ImageInfo,
  combinePath,
} from "./image";

const listFile = ".list";

export const getImageDb = async (storageUrl): Promise<ImageDb> => {
  const response = await apiClient.get<ImageDbRaw>("/images/list");
  return transformImageDb(response.data, storageUrl);
};

const removeImage = (db: ImageDb, path: string) => {
  const p = parseImagePath(db.storageUrl, path);
  const m = db.folderMap.get(p.folder);
  if (m) {
    const i = m.files.findIndex((b) => b.path === path);
    if (i >= 0) {
      const f = m.files[i];
      m.files.splice(i, 1);
      m.size -= f.size;
      db.size -= f.size;
      db.folderMap.set(p.folder, { ...m });
    }
  }
  db.imageMap.delete(path);
};

const pushImage = (db: ImageDb, i: ImageInfo) => {
  const p = parseImagePath(db.storageUrl, i.p);
  const b: BlobImage = {
    path: i.p,
    size: i.l,
    modified: i.m ?? new Date().toISOString(),
    ...p,
  };
  const f = p.folder || ROOT_FOLDER;
  if (!db.folderMap.get(f)) {
    db.folderMap.set(f, { files: [], size: 0 });
  }
  if (b.name !== listFile) {
    const ff = db.folderMap.get(f);
    ff.files.push(b);
    ff.size += b.size;
    db.size += b.size;
    db.imageMap.set(i.p, b);
  }
  return b;
};

const transformImageDb = (dbRaw: ImageDbRaw, storageUrl: string): ImageDb => {
  const db: ImageDb = {
    imageMap: new Map(),
    folderMap: new Map(),
    storageUrl,
    size: 0,
  };

  dbRaw.images.forEach((i) => {
    pushImage(db, i);
  });

  return db;
};

export const copyDb = (db: ImageDb): ImageDb => ({
  ...db,
  imageMap: new Map(db.imageMap),
  folderMap: new Map(db.folderMap),
});

export const addImageToDb = (db: ImageDb, path, length, oldPath?): ImageDb => {
  const newDb = copyDb(db);
  removeImage(newDb, oldPath ?? path);
  pushImage(newDb, { p: path, l: length });
  return newDb;
};

export const moveImagesDb = async (
  db: ImageDb,
  paths: string[],
  folder: string
): Promise<ImageDb> => {
  trackEvent({
    category: "image",
    action: "move",
    value: paths.length,
    label: folder,
  });
  const response = await apiClient.post<OperationResult<string[]>>(
    "/images/move",
    {
      paths,
      folder: folder === ROOT_FOLDER ? "" : folder,
    }
  );
  const moved = response.data?.value;
  if (!moved?.length) return db;

  const newDb = copyDb(db);
  moved.forEach((path) => {
    const i = newDb.imageMap.get(path);
    const newPath = combinePath(folder, i.name);
    removeImage(newDb, path);
    pushImage(newDb, { p: newPath, l: i.size });
  });
  return newDb;
};

export const deleteImagesDb = async (
  db: ImageDb,
  paths: string[]
): Promise<ImageDb> => {
  const response = await apiClient.post<OperationResult<string[]>>(
    "/images/delete",
    { paths }
  );
  const deleted = response.data?.value;

  if (!deleted?.length) return db;

  const newDb = copyDb(db);
  deleted.forEach((path) => {
    removeImage(newDb, path);
  });
  return newDb;
};

export const addFolderDb = async (
  db: ImageDb,
  folder: string
): Promise<ImageDb> => {
  trackEvent({
    category: "folder",
    action: "create image",
    label: folder,
    feature: true,
  });
  const response = await apiClient.post<BaseOperationResult>(
    "/images/add-folder",
    { folder }
  );

  if (!response.data.success) return db;

  const newDb = copyDb(db);
  newDb.folderMap.set(folder, { files: [], size: 0 });
  return newDb;
};

export const deleteFolderDb = async (
  db: ImageDb,
  folder: string
): Promise<ImageDb> => {
  trackEvent({ category: "folder", action: "delete", label: folder });
  const response = await apiClient.post<BaseOperationResult>(
    "/images/delete-folder",
    { folder }
  );

  if (!response.data.success) return db;

  const newDb = copyDb(db);

  const m = newDb.folderMap.get(folder);
  if (m) {
    m.files.forEach((f) => {
      newDb.size -= f.size;
      newDb.imageMap.delete(f.path);
    });
    newDb.folderMap.delete(folder);
  }
  return newDb;
};
