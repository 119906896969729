import { CatalogBuilder } from "./catalog.builder";
import { getPageGrid, updatePage } from "./catalog.page";
import { CatalogPageRef, CatalogSection, PageEntry } from "./catalogs";

export type CatalogStructure = {
  entries: PageEntry[];
  viewPages: CatalogPageRef[];
};

export const refreshCatalogStructure = (builder: CatalogBuilder) => {
  const entries: PageEntry[] = [];

  const sections = [...builder.sections];
  builder.sections = [];

  let viewPageIndex = 0;
  let section: CatalogSection = null;

  for (let i = 0; i < builder.pages.length; i++) {
    const page = builder.pages[i];

    if (page.pageItem?.di.Title && page.pageItem?.di.PageType !== "toc") {
      section = sections.find(
        (s) => s.id === page.pageItem.id || s.title === page.pageItem?.di.Title
      );
      section = {
        id: page.pageItem.id,
        title: page.pageItem?.di.Title,
        expanded: section?.expanded ?? true,
        start: page.pageId,
        end: page.pageId,
      };
      builder.sections.push(section);
    }

    if (section && !section.expanded) {
      let pages = [];
      const pi = builder.pages.findIndex(
        (p, n) => n > i && p.pageItem?.di.Title
      );
      const end = pi >= 0 ? pi - 1 : builder.pages.length - 1;
      pages = builder.pages.slice(page.pageId, end + 1);
      entries.push({
        id: viewPageIndex,
        range: {
          start: page.pageId,
          end,
          viewStart: viewPageIndex,
          viewEnd: viewPageIndex + pages.length - 1,
        },
        pages,
        section,
        multiple: end > page.pageId,
      });
      i = end;
      viewPageIndex += pages.length;
      section.end = end;
      continue;
    }

    if (section) section.end = page.pageId;

    const subcatalog = page.pageItem?.info.subcatalog;
    if (subcatalog) {
      const pages = subcatalog.pages ?? [];
      if (!pages.length) pages.push(page);
      entries.push({
        id: viewPageIndex,
        subcatalog,
        range: {
          start: page.pageId,
          end: page.pageId,
          viewStart: viewPageIndex,
          viewEnd: viewPageIndex + pages.length - 1,
        },
        pages: pages,
        multiple: true,
        readOnly: true,
        originalPage: page,
        section,
      });
      viewPageIndex += pages.length;
      continue;
    }

    const grid = getPageGrid(builder, page.pageId);
    if (grid) {
      let pages = [];
      if (grid.collection) {
        pages = grid.preview.pages;
        if (!pages.length) pages.push(page);
        entries.push({
          id: viewPageIndex,
          range: {
            start: page.pageId,
            end: page.pageId,
            viewStart: viewPageIndex,
            viewEnd: viewPageIndex + pages.length - 1,
          },
          pages: pages,
          grid,
          multiple: grid.preview.pages.length > 1,
          readOnly: true,
          originalPage: page,
          section,
        });
      } else {
        const end = Math.min(
          grid.expanded ? page.pageId : grid.end,
          builder.pages.length - 1
        );
        if (
          page.pageId <= end &&
          (grid.begin === page.pageId || grid.expanded)
        ) {
          pages = builder.pages.slice(page.pageId, end + 1);
          entries.push({
            id: viewPageIndex,
            range: {
              start: page.pageId,
              end,
              viewStart: viewPageIndex,
              viewEnd: viewPageIndex + pages.length - 1,
            },
            pages,
            grid,
            multiple: end > page.pageId,
            section,
          });
        }
        i = end;
      }
      viewPageIndex += pages.length;
    } else {
      entries.push({
        id: viewPageIndex,
        range: {
          start: page.pageId,
          end: page.pageId,
          viewStart: viewPageIndex,
          viewEnd: viewPageIndex,
        },
        pages: [page],
        section,
      });
      viewPageIndex++;
    }
  }

  const viewPages = entries
    .map((e) =>
      e.pages.map((p) => ({
        ...p,
        entry: e,
        pageId: e.originalPage?.pageId ?? p.pageId,
      }))
    )
    .flat()
    .map((p, i) => ({ ...p, viewPageId: i }));

  builder.structure = { entries, viewPages };

  viewPages.forEach((p) => {
    updatePage(builder, p);
  });
};

export const getOriginalPages = (entries: PageEntry[]): CatalogPageRef[] => {
  return entries
    .map((e) => (e.originalPage ? [e.originalPage] : e.pages))
    .flat();
};

export const setActivePageId = (
  builder: CatalogBuilder,
  pageId: number,
  isView?: boolean
) => {
  if (isView) {
    if (pageId < 0) {
      pageId = 0;
    }
    if (pageId >= builder.structure.viewPages.length) {
      pageId = builder.structure.viewPages.length - 1;
    }
    builder.context.viewPageIndex = pageId;
    builder.context.activePageId =
      builder.structure.viewPages[pageId].entry?.range.start ?? 0;
  } else {
    if (pageId < 0 || pageId >= builder.pages.length) {
      pageId = 0;
    }
    builder.context.activePageId = pageId ?? 0;
    const i = builder.structure.viewPages.findIndex((p) => pageId === p.pageId);
    builder.context.viewPageIndex = i >= 0 ? i : pageId;
  }
};
