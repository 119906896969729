import React from "react";

const siteKey = "6LfOL8wUAAAAAKE86ab48OtkoAxROTWwN53x7SUJ";

export const useRecaptcha = (action: string) => {
  const [token, setToken] = React.useState<string | null>(null);

  const generateToken = React.useCallback(
    async (retry = false) => {
      if (window.grecaptcha) {
        try {
          const newToken = await window.grecaptcha.execute(siteKey, { action });
          setToken(newToken);
        } catch (error) {
          if (!retry) setTimeout(() => generateToken(retry), 500);
          else console.error("Error generating reCAPTCHA token:", error);
          setToken(null); // In case of error, reset the token
        }
      }
    },
    [action]
  );

  React.useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.recaptcha.net/recaptcha/api.js?render=" + siteKey;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    script.addEventListener("load", () => generateToken(false));

    return () => {
      document.body.removeChild(script);
    };
  }, [generateToken]);

  // Optional: Refresh token periodically if needed
  // React.useEffect(() => {
  //   const interval = setInterval(generateToken, 100000); // Refresh every 100 seconds
  //   return () => clearInterval(interval);
  // }, [generateToken]);

  return { token, regenerateToken: generateToken };
};
