/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, Fragment, lazy, Suspense } from "react";
import { Outlet, RouteObject, useRoutes } from "react-router-dom";
import Home from "./containers/home/Home";
import Login from "./containers/auth/Login";
import NotFound from "./components/NotFound";
import Layout from "./containers/layouts/Layout";
import AuthGuard from "./contexts/AuthGuard";
import GuestGuard from "./contexts/GuestGuard";
import ExternaLogin from "./containers/auth/ExternaLogin";
import LoadingScreen from "./components/LoadingScreen";
import { PageMeta } from "./components/PageMeta";
import { Permission } from "./features/accounts/account";
import { BasicLayout } from "./containers/layouts/BasicLayout";
import AppHealth from "./containers/home/Health";
import { EmptyLayout } from "./containers/layouts/EmptyLayout";
import { RouteTable } from "./ui/routes";
import { DialogProvider } from "./contexts/DialogContext";
import ErrorBoundary from "./contexts/ErrorBoundary";
import { PageLogger } from "./contexts/PageLogger";
import Signup from "./containers/auth/Signup";

type RouteDef = {
  path?: string;
  title?: string;
  children?: RouteDef[];
  guard?: any;
  guardParams?: any;
  layout?: any;
  component?: any;
  caseSensitive?: boolean;
  index?: boolean;
  loading?: string;
  permission?: Permission;
};

const productLoadMessage = "loading product database...";

const AppLayout = () => (
  <ErrorBoundary>
    <DialogProvider>
      <Outlet />
    </DialogProvider>
  </ErrorBoundary>
);

const routeDefs: RouteDef[] = [
  {
    layout: AppLayout,
    children: [
      {
        path: "/login",
        title: "Sign In",
        component: Login,
        guard: GuestGuard,
      },
      {
        path: "/signup",
        title: "Sign Up",
        component: Signup,
        guard: GuestGuard,
      },
      {
        path: "/forgot-password",
        title: "Forgot Password",
        component: lazy(() => import("./containers/auth/ForgotPassword")),
        guard: GuestGuard,
      },
      {
        path: "/reset-password",
        title: "Reset Password",
        component: lazy(() => import("./containers/auth/ResetPassword")),
        guard: GuestGuard,
      },
      {
        path: "/admin-login",
        title: "Sign In",
        component: lazy(() => import("./containers/auth/AdminLogin")),
        guard: AuthGuard,
        guardParams: { su: true },
      },
      {
        path: "/external-login",
        component: ExternaLogin,
        layout: BasicLayout,
      },
      {
        path: "/join",
        title: "Join Account",
        component: lazy(() => import("./containers/account/Join")),
        guard: GuestGuard,
      },
      {
        path: "/health-app",
        component: AppHealth,
      },
      {
        path: "/",
        component: Layout,
        guard: AuthGuard,
        children: [
          {
            index: true,
            component: Home,
            title: "Dashboard",
            loading: "loading dashboard...",
          },
          {
            path: "/account",
            title: "Account",
            component: lazy(() => import("./containers/account/Account")),
            loading: "loading account...",
          },
          // {
          //   path: "/start",
          //   title: "Start",
          //   component: lazy(() => import("./containers/account/Start")),
          // },
          {
            path: "/products/categories",
            title: "Categories",
            guard: AuthGuard,
            permission: "products",
            component: lazy(
              () => import("./containers/products/categories/CategoryListView")
            ),
            loading: productLoadMessage,
          },
          {
            path: "/products/collections",
            title: "Collections",
            guard: AuthGuard,
            permission: "products",
            component: lazy(
              () => import("./containers/products/collections/CollectionPage")
            ),
            loading: productLoadMessage,
          },
          {
            path: "/products",
            title: "Products",
            component: lazy(() => import("./containers/products/grid")),
            loading: productLoadMessage,
          },
          {
            path: "/import",
            title: "Import",
            permission: "products",
            component: lazy(() => import("./containers/products/import")),
            loading: productLoadMessage,
          },
          {
            path: "/import/shopify",
            title: "Shopify Import",
            permission: "products",
            component: lazy(
              () => import("./containers/products/import/shopify/ShopifyImport")
            ),
            loading: productLoadMessage,
          },
          {
            path: "/images",
            title: "Images",
            component: lazy(() => import("./containers/images")),
            loading: "loading image database...",
          },
          {
            path: "/catalogs",
            title: "Catalogs",
            component: lazy(() => import("./containers/catalogs/CatalogList")),
          },
          {
            path: "/catalogs/create",
            title: "New Catalog",
            permission: "catalogs",
            component: lazy(
              () => import("./containers/catalogs/create/CreateCatalog")
            ),
          },
          {
            path: "/catalogs/templates",
            title: "Catalog Templates",
            component: lazy(
              () => import("./containers/catalogs/create/StartTemplates")
            ),
          },
          {
            path: "/parameters",
            title: "Parameters",
            component: lazy(() => import("./containers/parameters/Parameters")),
          },
          {
            path: "/parameters/:paramId",
            title: "Parameters",
            component: lazy(() => import("./containers/parameters/Parameters")),
          },
          {
            path: "/pg",
            title: "Playground",
            component: lazy(() => import("./containers/playground")),
          },
          {
            path: "/pg/editor",
            title: "Playground Editor",
            component: lazy(
              () => import("./containers/playground/CustomEditor")
            ),
          },
        ],
      },
      {
        path: "/start",
        title: "Start",
        component: lazy(() => import("./containers/start/Start")),
        guard: AuthGuard,
      },

      {
        path: "/design",
        component: EmptyLayout,
        guard: AuthGuard,
        permission: "catalogs",
        children: [
          {
            title: "Designer Playground",
            index: true,
            component: lazy(
              () => import("./containers/playground/DesignerPlayground")
            ),
          },
          {
            title: "Catalog Designer",
            path: RouteTable.catalogDesign + "/:catalogId",
            component: lazy(
              () => import("./containers/designer/catalogs/CatalogDesigner")
            ),
          },
          {
            title: "Product Card Designer",
            path: RouteTable.cards,

            component: lazy(
              () =>
                import("./containers/designer/templates/TemplateDesignerPage")
            ),
          },

          {
            title: "Layout Designer",
            path: RouteTable.layouts,

            component: lazy(
              () => import("./containers/designer/layouts/LayoutDesigner")
            ),
          },
        ],
      },
      {
        path: "*",
        title: "Not Found",
        component: NotFound,
        layout: BasicLayout,
      },
    ],
  },
];

const buildRoutes = (defs: RouteDef[]): RouteObject[] => {
  return defs.map((d) => {
    const Guard = d.guard ?? Fragment;
    const Component = d.component ?? Fragment;
    const Layout = d.layout ?? Fragment;
    const gp = d.guard ? { ...d.guardParams, permission: d.permission } : {};
    const r = {
      path: d.path,
      element: (
        <>
          <PageLogger />
          <Guard {...gp}>
            {d.title && <PageMeta title={d.title + " | Catalog Machine"} />}
            <Layout>
              <Suspense
                fallback={<LoadingScreen title={d.loading || "loading data"} />}
              >
                <Component />{" "}
              </Suspense>
            </Layout>
          </Guard>
        </>
      ),

      index: d.index,
      caseSensitive: d.caseSensitive,
    };
    return d.index
      ? { ...r, index: true }
      : { ...r, index: false, children: d.children && buildRoutes(d.children) };
  });
};

export const AppRoutes: FC = () => {
  const routes = buildRoutes(routeDefs);
  const element = useRoutes(routes);

  return <>{element}</>;
};

// eslint-disable-next-line react-refresh/only-export-components
export const createAppRoutes = () => {
  const routes = buildRoutes(routeDefs);
  return routes;
};

export default AppRoutes;
