import React from "react";
import {
  Box,
  Breakpoint,
  FormControl,
  Input,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Popover,
} from "@mui/material";
import { HexColorPicker } from "react-colorful";
import { colorSwatches } from "../ui/constants";
import useIsMobile from "../ui/useIsMobile";

interface ColorPickerProps {
  color?: string;
  setColor: (color: string) => void;
  label?: string;
  size?: "small" | "medium";
}

export const ColorPickerPopover = ({
  color,
  setColor,
  anchor,
  onClose,
}: ColorPickerProps & { anchor: HTMLElement; onClose: () => void }) => {
  return (
    <Popover
      open={!!anchor}
      anchorEl={anchor}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Box
        sx={{
          p: 1,
          //   ml: 1,
          "& .react-colorful": { width: 175, height: 175 },
          "& .react-colorful__hue-pointer": {
            width: 16,
            borderRadius: 3,
          },
          "& .react-colorful__saturation-pointer": {
            width: 16,
            height: 16,
            borderRadius: 3,
          },
        }}
      >
        <HexColorPicker color={color ?? ""} onChange={setColor} />
        <Box
          sx={{
            pt: 2,
            pl: 0.5,
            display: "flex",
            flexWrap: "wrap",
            width: 175,
          }}
        >
          {colorSwatches.map((cl) => {
            const c = cl.toLowerCase();
            return (
              <Box
                key={c}
                title={c}
                sx={{
                  background: c,
                  width: 20,
                  height: 20,
                  mr: 0.5,
                  mb: 0.5,
                  border: "1px solid #ccc",
                  borderRadius: 0.5,
                  cursor: "pointer",
                }}
                onClick={() => setColor(c)}
              />
            );
          })}
        </Box>
      </Box>
    </Popover>
  );
};

export const ColorAdornment = ({
  color,
  setColor,
  boxSize,
}: ColorPickerProps & { boxSize: number }) => {
  const [colorAnchor, setColorAnchor] = React.useState<HTMLElement>(null);
  return (
    <InputAdornment position="start">
      <Box
        sx={{
          width: boxSize,
          height: boxSize,
          borderRadius: 1,
          border: "3px solid #fff",
          boxShadow:
            "0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1)",
          cursor: "pointer",
          backgroundColor: color,
        }}
        onClick={(e) => setColorAnchor(e.currentTarget)}
      ></Box>
      <ColorPickerPopover
        color={color}
        setColor={setColor}
        anchor={colorAnchor}
        onClose={() => setColorAnchor(null)}
      />
    </InputAdornment>
  );
};

export const OutlinedColorPicker = ({
  color,
  setColor,
  label,
  size,
}: ColorPickerProps) => {
  label = label ?? "Color";
  return (
    <FormControl
      sx={{ width: size === "small" ? 120 : 150 }}
      variant="outlined"
      size={size}
    >
      <InputLabel htmlFor="color-picker">{label}</InputLabel>
      <OutlinedInput
        id="color-picker"
        value={color ?? ""}
        label={label}
        onChange={(e) => {
          setColor(e.target.value);
        }}
        startAdornment={
          <ColorAdornment
            color={color}
            setColor={setColor}
            boxSize={size === "small" ? 20 : 28}
          />
        }
      />
    </FormControl>
  );
};

export const ColorPicker = ({ color, setColor, size }: ColorPickerProps) => {
  return (
    <FormControl
      sx={{ width: size == "small" ? 80 : 100 }}
      variant="standard"
      size="small"
    >
      <Input
        id="color-picker"
        value={color ?? ""}
        onChange={(e) => {
          setColor(e.target.value);
        }}
        startAdornment={
          <ColorAdornment color={color} setColor={setColor} boxSize={20} />
        }
        sx={{
          "::before": { border: "none" },
          " & .MuiInputBase-input": { pt: 0.5 },
          fontSize: size == "small" ? 12 : "inherit",
        }}
        size={size}
      />
    </FormControl>
  );
};

export const ResponsiveColorPicker = ({
  breakpoint,
  ...props
}: ColorPickerProps & { breakpoint: Breakpoint }) => {
  const isMobile = useIsMobile(breakpoint);
  if (isMobile) {
    return <ColorAdornment {...props} boxSize={25} />;
  } else {
    return <ColorPicker {...props} size="small" />;
  }
};
