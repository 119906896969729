import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { HtmlEditorProps } from "../remirror/editor.context";
import { HtmlEditor } from "../remirror/RemirrorEditor";

export type HtmlEditorDialogProps = {
  isOpen?: boolean;
  onClose?: () => void;
} & HtmlEditorProps;

export type OpenHtmlEditorModalFunc = (params: HtmlEditorProps) => void;

export const HtmlEditorDialog = ({
  value,
  onChange,
  isOpen,
  onClose,
  fields,
  fieldArgs,
  fullScreen: isFullScreen,
}: HtmlEditorDialogProps) => {
  const [html, setHtml] = React.useState(value);
  const [fullScreen, setFullScreen] = React.useState(isFullScreen);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      fullScreen={fullScreen}
    >
      <DialogTitle>Text Editor</DialogTitle>
      <DialogContent>
        <HtmlEditor
          value={html}
          onChange={setHtml}
          fields={fields}
          fieldArgs={fieldArgs}
          fullScreen={fullScreen}
          setFullScreen={setFullScreen}
        />
      </DialogContent>
      <DialogActions
        sx={{ justifyContent: fullScreen ? "center" : "flex-end" }}
      >
        <Button onClick={onClose}>Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            onChange(html);
            onClose();
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
