import { Suspense } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { SnackbarProvider } from "notistack";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { createAppRoutes } from "./AppRoutes";

import ErrorBoundary from "./contexts/ErrorBoundary";
import AppGlobalStyles from "./components/AppGlobalStyles";
import { appTheme } from "./ui/theme";
import { LogProvider } from "./contexts/LogContext";
import { initLogging } from "./services/logging";
import { LicenseInfo } from "@mui/x-license-pro";
import LoadingScreen from "./components/LoadingScreen";

import "./ui/css/general.scss";

initLogging();
LicenseInfo.setLicenseKey(
  "3e5ac563deaf6503d9e9e74679af04d1Tz03NjMwMixFPTE3Mjg0ODg3MDAwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

const router = createBrowserRouter(createAppRoutes());

const App = () => {
  //const theme = createAppTheme();

  return (
    <ThemeProvider theme={appTheme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SnackbarProvider dense maxSnack={3}>
          <ErrorBoundary>
            <LogProvider>
              <Suspense fallback={<LoadingScreen title="loading app" />}>
                <CssBaseline />
                <AppGlobalStyles />
                <RouterProvider router={router} />
              </Suspense>
            </LogProvider>
          </ErrorBoundary>
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default App;
