import React from "react";
import { useSearchParams } from "react-router-dom";
import { useAtom } from "jotai";
import { CategoryRef, ProductDb, ProductRef } from "./product";
import { productDbAtom } from "./product.state";
import { useDialogs } from "../../contexts/useDialogs";
import { equalsCI } from "../data/data-types";

export interface ProductModalState {
  editOptions: ProductEditOptions;
  setEditOptions: (opt: ProductEditOptions) => void;
  deleteOptions: ProductDeleteOptions;
  setDeleteOptions: (opt: ProductDeleteOptions) => void;
  categoryOptions: CategoryEditOptions;
  setCategoryOptions: (opt: CategoryEditOptions) => void;
  updateOptions: UpdateProductOptions;
  setUpdateOptions: (opt: UpdateProductOptions) => void;
  collectionAttrsOptions: CollectionAttrsOptions;
  setCollectionAttrsOptions: (opt: CollectionAttrsOptions) => void;
  collectionDeleteOptions: CollectionDeleteOptions;
  setCollectionDeleteOptions: (opt: CollectionDeleteOptions) => void;
  collectionEditorOptions: CollectionEditorOptions;
  setCollectionEditorOptions: (opt: CollectionEditorOptions) => void;
}

export interface ProductEditOptions {
  productId?: string;
  categoryId?: string;
  collectionId?: string;
  onChange?: (cr: ProductRef) => void;
  shouldUpdateQueryString?: boolean;
}

export interface ProductDeleteOptions {
  ids: string[];
  title: string;
  onDelete?: () => void;
}

export type CollectionDeleteOptions = {
  collectionId: string;
  onDelete: () => void;
};

export type CollectionAttrsOptions = {
  collectionId?: string;
  parentId?: string;
  onChange: (id: string) => void;
  withItems?: boolean;
};

export type CollectionEditorOptions = {
  collectionId: string;
  onChange: (id: string) => void;
};

export interface CategoryEditOptions {
  categoryId?: string;
  onChange?: (cr: CategoryRef) => void;
  onDelete?: () => void;
}

export type UpdateProductOptions =
  | {
      categoryId: string;
      target: "category";
      onChange: (categoryId?: string) => void;
    }
  | {
      collectionIds: string[];
      target: "collection";
      onChange: (collectionIds?: string[], db?: ProductDb) => void;
    };

export type OpenDeleteProductFunc = (opts: ProductDeleteOptions) => void;
export type OpenEditProductFunc = (opts: ProductEditOptions) => void;
export type OpenCollectionEditorFunc = (opts: CollectionEditorOptions) => void;
export type OpenCollectionAttrsFunc = (opts: CollectionAttrsOptions) => void;
export type OpenCollectionDeleteFunc = (opts: CollectionDeleteOptions) => void;
export type OpenEditCategoryFunc = (opts: CategoryEditOptions) => void;
export type OpenUpdateProductFunc = (opts: UpdateProductOptions) => void;

export const useProductModalState = (): ProductModalState => {
  const [editOptions, setEditOptions] = React.useState<ProductEditOptions>();
  const [deleteOptions, setDeleteOptions] =
    React.useState<ProductDeleteOptions>();
  const [collectionEditorOptions, setCollectionEditorOptions] =
    React.useState<CollectionEditorOptions>();
  const [collectionAttrsOptions, setCollectionAttrsOptions] =
    React.useState<CollectionAttrsOptions>();
  const [collectionDeleteOptions, setCollectionDeleteOptions] =
    React.useState<CollectionDeleteOptions>();
  const [categoryOptions, setCategoryOptions] =
    React.useState<CategoryEditOptions>();
  const [updateOptions, setUpdateOptions] =
    React.useState<UpdateProductOptions>();

  return {
    editOptions,
    setEditOptions,
    deleteOptions,
    setDeleteOptions,
    categoryOptions,
    setCategoryOptions,
    updateOptions,
    setUpdateOptions,
    collectionAttrsOptions,
    setCollectionAttrsOptions,
    collectionDeleteOptions,
    setCollectionDeleteOptions,
    collectionEditorOptions,
    setCollectionEditorOptions,
  };
};

export const useProductModalRouteHandler = () => {
  const [productDb] = useAtom(productDbAtom);
  const [searchParams] = useSearchParams();
  const { productModalApi } = useDialogs();

  React.useEffect(() => {
    const code = searchParams.get("product");
    if (code && !productModalApi.productId) {
      const productId = Array.from(productDb.productMap.values()).find((p) =>
        equalsCI(code, p.product.code)
      )?.product.id;
      if (productId) productModalApi.openEditProduct({ productId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);
};
