type DateStyle = "full" | "long" | "medium" | "short";

export const formatDateObj = (
  d?: Date,
  time?: boolean,
  dateStyle?: DateStyle
) => {
  if (!d) return "";
  return Intl.DateTimeFormat(
    "default",
    time
      ? { dateStyle: dateStyle ?? "short", timeStyle: "short" }
      : { month: "numeric", day: "numeric", year: "numeric" }
  ).format(d);
};

export const toUtcFormat = (dateStr: string) => {
  return dateStr && dateStr[dateStr.length - 1] !== "Z"
    ? dateStr + "Z"
    : dateStr;
};

export const formatDateStr = (
  dateStr: string,
  time?: boolean,
  isUtc = true,
  style?: DateStyle
) => {
  if (!dateStr) return "";
  return formatDateObj(parseDate(dateStr, isUtc), time, style);
};

export const parseDate = (dateStr: string, isUtc = false) => {
  if (!dateStr) return undefined;
  if (isUtc) dateStr = toUtcFormat(dateStr);
  const d = Date.parse(dateStr);
  return new Date(d);
};

const to00 = (n) => {
  return (n < 10 ? "0" : "") + n;
};

export const getTimeStamp = () => {
  const d = new Date();
  return (
    d.getFullYear() +
    to00(d.getMonth() + 1) +
    to00(d.getDate()) +
    to00(d.getHours()) +
    to00(d.getMinutes())
  );
};
