import React from "react";
import { Box } from "@mui/material";
import { NavLink as RouterLink } from "react-router-dom";

export const BasicLayout: React.FC = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <Box
      sx={{
        minHeight: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
        <RouterLink to="/">
          <img alt="Logo" src="/images/logo.png" />
        </RouterLink>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexGrow: 1,
          flexDirection: "column",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
