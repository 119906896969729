import React from "react";
import { Box, Button } from "@mui/material";
import {
  useGridApiRef,
  DataGridPro,
  GridActionsCellItem,
  GridColDef,
  GridPreProcessEditCellProps,
  GridRowOrderChangeParams,
} from "@mui/x-data-grid-pro";
import { WarningDialog } from "../../../components/dialogs/WarningDialog";
import { SelectEditCell } from "../../../components/grids/SelectEditCell";
import {
  DataTypeName,
  applyDifference,
  DataTypeNames,
  swapEntries,
  equalsCI,
} from "../../../features/data/data-types";
import {
  Category,
  ProductField,
  reservedFieldNames,
  reservedFields,
} from "../../../features/products/product";
import { IconTypes } from "../../../components/icons.types";
import { useSnackbar } from "notistack";

interface CategoryFieldsProps {
  category: Category;
  onChange: (category: Category) => void;
}

export const CategoryFields = ({ category, onChange }: CategoryFieldsProps) => {
  const [toDeleteField, setDeleteField] = React.useState<ProductField>();
  const apiFieldRef = useGridApiRef();
  const { enqueueSnackbar } = useSnackbar();

  const addField = React.useCallback(() => {
    const id = -category.fields.length;
    const f: ProductField = {
      fieldId: id,
      dataType: DataTypeName.Text,
      name: "",
    };
    const fields = [...category.fields, f];
    onChange({ ...category, fields });
    setTimeout(() => {
      apiFieldRef.current.startCellEditMode({ id: id, field: "name" });
    });
  }, [apiFieldRef, category, onChange]);

  const processFieldUpdate = React.useCallback(
    (newRow, oldRow) => {
      const fields = [...category.fields];
      const editedField = fields.find((f) => f.fieldId === newRow.id);
      if (editedField) {
        const name = newRow.name?.trim().toLowerCase();
        let error = "";
        if (reservedFieldNames.some((f) => f.toLowerCase() === name)) {
          error = newRow.name + " is reserved field name";
        }
        if (
          fields.some(
            (f) => f.fieldId !== newRow.id && f.name.toLowerCase() === name
          )
        ) {
          error = "Field name must be unique: " + newRow.name;
        }
        if (error) {
          enqueueSnackbar(error, {
            variant: "error",
          });
          apiFieldRef.current.startCellEditMode({
            id: newRow.id,
            field: "name",
          });
          return oldRow;
        }
        applyDifference(editedField, newRow, oldRow);
        onChange({ ...category, fields });
      }
      return newRow;
    },
    [apiFieldRef, category, enqueueSnackbar, onChange]
  );

  const deleteField = React.useCallback(
    (id) => {
      const fields = category.fields.filter((f) => f.fieldId !== id);
      onChange({ ...category, fields });
      setDeleteField(null);
    },
    [category, onChange]
  );

  const fieldColumns: GridColDef[] = React.useMemo(
    () => [
      {
        field: "name",
        headerName: "Field",
        flex: 1,
        minWidth: 100,
        editable: true,
        preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
          const hasError = params.props.value.length < 1;
          return { ...params.props, error: hasError };
        },
      },
      {
        field: "dataType",
        headerName: "Data Type",
        editable: true,
        renderEditCell: (params) => (
          <SelectEditCell
            {...params}
            entries={DataTypeNames.map((t) => ({ name: t, value: t }))}
          />
        ),
      },
      {
        field: "actions",
        type: "actions",
        width: 35,
        getActions: (params) => {
          const df = category.fields.find((f) => f.fieldId === params.id);
          return df && !reservedFields.some((f) => f.name === df.name)
            ? [
                <GridActionsCellItem
                  icon={<IconTypes.DeleteAction />}
                  label="Delete field"
                  key="1"
                  onClick={() => {
                    if (df.fieldId > 0) setDeleteField(df);
                    else deleteField(df.fieldId);
                  }}
                />,
              ]
            : [];
        },
      },
    ],
    [deleteField, category?.fields]
  );

  const fieldToRow = (f: ProductField) => ({
    id: f.fieldId,
    __reorder__: f.name,
    ...f,
  });

  const fieldRows = React.useMemo(() => {
    return category?.fields.map((f) => fieldToRow(f)) ?? [];
  }, [category?.fields]);

  const handleFieldOrderChange = React.useCallback(
    (params: GridRowOrderChangeParams) => {
      const fields = swapEntries(
        category.fields,
        params.oldIndex,
        params.targetIndex
      );
      onChange({ ...category, fields });
    },
    [category, onChange]
  );

  return (
    <Box>
      <Box sx={{ mb: 1 }}>
        <Button onClick={() => addField()} size="small">
          + Add new field
        </Button>
      </Box>
      <DataGridPro
        apiRef={apiFieldRef}
        rows={fieldRows}
        columns={fieldColumns}
        rowReordering
        onRowOrderChange={handleFieldOrderChange}
        autoHeight
        hideFooter
        //experimentalFeatures={{ newEditingApi: true }}
        isCellEditable={(params) =>
          params.field !== "Field" ||
          !reservedFields.some((f) => equalsCI(f.name, params.row.name))
        }
        processRowUpdate={(newRow, oldRow) =>
          processFieldUpdate(newRow, oldRow)
        }
      />
      <WarningDialog
        open={!!toDeleteField}
        onClose={() => {
          setDeleteField(null);
        }}
        onConfirm={() => deleteField(toDeleteField.fieldId)}
        title={`Delete field ${toDeleteField?.name ?? ""}?`}
        action="Delete"
        color="error"
      >
        The field will be deleted from all products in this category
      </WarningDialog>
    </Box>
  );
};
