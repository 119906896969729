import React from "react";
import { Badge, Box } from "@mui/material";

import { renderTemplate, templateSortBy } from "./template.render";
import { useAtom } from "jotai";
import { createBuilder } from "../../../features/catalogs/catalog.builder";
import { createTempCatalog } from "../../../features/catalogs/catalogs";
import { templateDbAtom } from "../../../features/templates/template.state";
import { startElementDrag, endElementDrag } from "../designer.func";
import { useBuilder } from "../useBuilder";
import { Template } from "../../../features/templates/templates";

export type TemplateViewOptions = {
  filter?: string;
  sort?: string;
};

const wrapDefaultBadge = (content: React.ReactNode, template?: Template) => {
  if (
    !template?.isDefault &&
    !template?.isDefaultDetails &&
    !template?.isDefaultList
  )
    return content;

  const label =
    (template?.isDefault ? "G " : "") +
    (template?.isDefaultDetails ? "D " : "") +
    (template?.isDefaultList ? "L " : "");

  const title =
    (template?.isDefault ? " Grid " : "") +
    (template?.isDefaultDetails ? " Details " : "") +
    (template?.isDefaultList ? " List " : "");

  return (
    <Badge
      key={template.id}
      badgeContent={label}
      color="primary"
      title={title}
      sx={{ "& .MuiBadge-badge": { right: label.length * 3 } }}
    >
      {content}
    </Badge>
  );
};

type TemplateListProps = {
  //builder: CatalogBuilder;
  productId?: string;
  onClick?: (templateId: string) => void;
  isDraggable?: boolean;
  selectedId?: string;
  view?: TemplateViewOptions;
  showDefault?: boolean;
};

export const TemplateList = ({
  productId,
  onClick,
  isDraggable,
  selectedId,
  view,
  showDefault,
}: TemplateListProps) => {
  const { builder, setBuilder } = useBuilder();
  const [templateDb] = useAtom(templateDbAtom);

  React.useEffect(() => {
    const b = createBuilder(createTempCatalog());
    setBuilder(b);
  }, [setBuilder]);

  React.useEffect(() => {
    if (templateDb && builder?.data?.version >= 0) {
      builder.data.version++;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateDb]);

  if (!builder?.ready) return null;

  const vs =
    templateSortBy.find((s) => s.value === view?.sort) ?? templateSortBy[0];

  const templates = templateDb
    .filterList(view?.filter, (t) => t.template.name)
    .sortList((t) => t.template[vs.value].toLowerCase(), vs.o);

  return (
    <Box
      sx={{
        p: 1,
        display: "flex",
        gap: 2,
        flexWrap: "wrap",
        justifyContent: "center",
      }}
    >
      {templates.map((t) =>
        wrapDefaultBadge(
          <Box
            key={t.template.id}
            className={`element sidebar-element template-element ${
              selectedId === t.template.id ? "selected" : ""
            }`}
            draggable={isDraggable}
            onDragStart={(e) =>
              startElementDrag(e, {
                type: "template",
                productId: productId,
                templateId: t.template.id,
                content: "",
              })
            }
            onDragEnd={endElementDrag}
            onClick={() => onClick?.(t.template.id)}
          >
            {renderTemplate(builder, t.template.id, productId, 500, 500)}

            <div className="title">{t.template.name}</div>
          </Box>,
          showDefault ? t.template : undefined
        )
      )}
    </Box>
  );
};
