import React from "react";
import { useAtom } from "jotai";

import { ImportJobResult, ImportJobStatus } from "./import";
import { importAtom } from "./import.state";
import { trackEvent } from "../../services/analytics";
import apiClient from "../../services/apiClient";
import { useProducts } from "../products/product-func";

export type ImportJobParams = {
  onFinish: (status: ImportJobStatus) => void;
  onContinue?: (status: ImportJobStatus) => void;
  serviceJobId: string;
};

export const useImportJob = ({
  serviceJobId,
  onFinish,
  onContinue,
}: ImportJobParams) => {
  const [importState, setImportState] = useAtom(importAtom);

  const { reloadProductDb } = useProducts();
  const [importing, setImporting] = React.useState(false);
  const [imported, setImported] = React.useState(false);
  const [currentJob, setCurrentJob] = React.useState<string>();
  const [jobStatus, setJobStatus] = React.useState<ImportJobStatus>();
  const [lastJobStatus, setLastJobStatus] = React.useState<{
    checked?: boolean;
    status?: ImportJobStatus;
  }>({});

  const getJobStatus = React.useCallback(async (jobId: string) => {
    const response = await apiClient.get<ImportJobStatus>(
      "/data/job-status?jobId=" + jobId
    );
    return response.data;
  }, []);

  const finishJob = React.useCallback(
    async (status: ImportJobStatus, result: ImportJobResult) => {
      // const collectionId = status.job?.parameters?.["collectionId"];
      // if (collectionId) {
      //   await modifyCollectionItems(collectionId, result.productIds, false);
      // }

      await reloadProductDb();
      trackEvent({
        category: serviceJobId,
        action: "finish",
        value: result.productIds.length,
      });
      setCurrentJob(null);
      setLastJobStatus({ checked: true, status });
      setImportState({ currentJob: null });
      setImporting(false);
      setImported(true);
      onFinish(status);
    },
    [onFinish, reloadProductDb, serviceJobId, setImportState]
  );

  const startJob = React.useCallback(
    async (jobId: string, name?: string, count?: number) => {
      if (currentJob) return;
      trackEvent({
        category: serviceJobId,
        action: "start",
        label: name,
        value: count,
        feature: !!name,
      });
      setCurrentJob(jobId);
      setImportState({ currentJob: jobId });
      setImporting(true);
      setImported(false);
    },
    [currentJob, serviceJobId, setImportState]
  );

  const resetJob = React.useCallback(() => {
    setJobStatus(null);
    setImporting(false);
  }, []);

  React.useEffect(() => {
    const check = async () => {
      const status = await getJobStatus(serviceJobId);
      if (status?.job) {
        if (!status.job.finished) {
          setJobStatus(status);
          startJob(serviceJobId);
          onContinue?.(status);
        } else if (importState.currentJob) {
          setImportState({ currentJob: null });
          setImporting(false);
          setImported(true);
          setJobStatus(null);
          await reloadProductDb();
        }
      }
      setLastJobStatus({ checked: true, status });
    };
    check();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (!currentJob) return;
    const intervalId = setInterval(async () => {
      const jobStatus = await getJobStatus(currentJob);
      setJobStatus(jobStatus);
      if (jobStatus?.job?.finished) {
        clearInterval(intervalId);
        const result = await apiClient.get<ImportJobResult>(
          "/data/job-result?jobId=" + currentJob
        );
        finishJob(jobStatus, result.data);
      }
    }, 3000);
    return () => {
      clearInterval(intervalId);
    };
  }, [currentJob, finishJob, getJobStatus, onFinish]);

  return {
    startJob,
    jobStatus,
    resetJob,
    lastJobStatus,
    importing,
    setImporting,
    imported,
  };
};
