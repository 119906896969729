import { v4 as uuidv4 } from "uuid";
import { CatalogBuilder } from "./catalog.builder";
import { updateItemStyle, newItem } from "./catalog.item";
import {
  CatalogItemRef,
  CatalogPageRef,
  CatalogPageType,
  PageEntry,
  PageItemConfig,
} from "./catalogs";
import { roundCoord, templateGridSize } from "../design/designer";

import { PageDimensions, PageOptions } from "./catalog.design";
import { getImageSrc } from "./catalog.func";
import { EvalContentArgs } from "../eval/content.eval.types";
import { ProductRef } from "../products/product";

export const getDimensions = (
  builder: CatalogBuilder,
  page: CatalogPageRef,
  opts?: PageOptions
): PageDimensions => {
  //page = page || builder.pages[pageNum];

  const pageLen = (builder.structure.viewPages ?? builder.pages).length ?? 0;
  const pageNum = page.viewPageId ?? page.pageId ?? 0;

  opts = opts || {};
  const showHeaderFooter =
    builder.catalog &&
    !page?.pageItem?.info?.config?.hideHeader &&
    (opts.includeHeaderFooter ||
      (builder.catalog.headerOnFirst && pageNum === 0) ||
      (pageNum > 0 && pageNum < pageLen - 1) ||
      (pageNum === pageLen - 1 && builder.catalog.headerOnLast));
  const hh =
    showHeaderFooter && builder.catalog.header
      ? builder.catalog.headerHeight || 0
      : 0;
  const fh =
    showHeaderFooter && builder.catalog.footer
      ? builder.catalog.footerHeight || 0
      : 0;
  return {
    scaleX: builder.board.scaleX,
    scaleY: builder.board.h / (templateGridSize + hh + fh),
    header: hh,
    footer: fh,
    opts: opts,
  };
};

export const updatePageConfig = (
  item: CatalogItemRef,
  type?: CatalogPageType,
  config?: PageItemConfig
) => {
  item.version++;
  if (type) item.di.PageType = type;
  if (config) {
    item.di.Body = JSON.stringify(config || {});
    if (config.title) item.di.Title = config.title;
  }
};

export const initPage = (
  builder: CatalogBuilder,
  page: CatalogPageRef,
  type?: CatalogPageType,
  config?: PageItemConfig,
  isTemp = false
) => {
  page.pageItem = page.items.find((i) => i.di.Type === "page");
  if (!page.pageItem) {
    page.pageItem = newItem({
      Type: "page",
      Body: JSON.stringify(config || {}),
      ImgPosition: "Fit",
      Color: "",
      PageId: page.pageId,
      PageType: type || "page",
      Title: config ? config.title : undefined,
      x: 0,
      y: 0,
      w: 0,
      h: 0,
    });
    page.items.push(page.pageItem);
    if (isTemp) {
      if (config?.hideHeader) {
        page.pageItem.di.Body = JSON.stringify({ hideHeader: true });
      }
    } else {
      builder.items.push(page.pageItem);
    }
  } else {
    updatePageConfig(page.pageItem, type, config);
  }
  //convert to the new format
  if (!page.pageItem.di.PageType) {
    page.pageItem.di.PageType = "page";
    page.pageItem.di.Body = JSON.stringify({ img: page.pageItem.di.Body });
  }
  page.pageItem.info = { config: JSON.parse(page.pageItem.di.Body || "{ }") };

  if (page.pageItem.di.PageType === "toc") {
    page.readonly = true;
    //page.pageItem.info.generated = true;
    const isPrevToc =
      page.pageId > 0 &&
      builder.pages[page.pageId - 1].pageItem &&
      builder.pages[page.pageId - 1].pageItem.di.PageType === "toc" &&
      page.pageItem.info.config.globalToc ===
        builder.pages[page.pageId - 1].pageItem.info.config.globalToc;
    page.pageItem.info.nextToc = isPrevToc;

    if (!page.pageItem.di.Title) page.pageItem.di.Title = "Table of Contents";

    // page.pageItem.info.thumbTitle = page.pageItem.info.config.globalToc
    //   ? "Global Table of Contents"
    //   : "Table of Contents";

    if (isPrevToc) {
      //page.pageItem.di.Title = "Table of Contents";
      page.pageItem.info.title = "Table of Contents (cont'd)";
      //page.pageItem.info.thumbTitle += "  (cont'd)";
    } else {
      page.pageItem.info.title = page.pageItem.di.Title;
      if (!page.pageItem.info.config.orderByToc)
        page.pageItem.info.config.orderByToc = "Page Number";
      if (!page.pageItem.info.config.sectionStyle)
        page.pageItem.info.config.sectionStyle = "";
      if (!page.pageItem.info.config.tocFontSize)
        page.pageItem.info.config.tocFontSize = 12;
      if (!page.pageItem.info.config.tocSectionFontColor)
        page.pageItem.info.config.tocSectionFontColor =
          page.pageItem.info.config.fontColor;
    }
  }
  if (page.pageItem.di.PageType === "collection") {
    page.readonly = true;
    //page.pageItem.info.generated = true;
  }
  if (page.pageItem.di.PageType === "subcatalog") {
    page.readonly = true;
    page.pageItem.info.subcatalog = builder.data.catalogs.find(
      (c) => c.catalog.id === page.pageItem.info.config.catalogId
    );
    //page.pageItem.info.generated = true;
    // const catalogId = page.pageItem.info.config.catalogId;
    // const cat = builder.data.catalogs.find((i) => i.catalog.id === catalogId);

    // const ctn = cat
    //   ? "<a href='" +
    //     "/builder/catalog/" +
    //     "/" +
    //     cat.catalog.id +
    //     "'>" +
    //     cat.catalog.name +
    //     "</a>"
    //   : "<Unknown Catalog>";
    // page.pageItem.info.title = page.pageItem.info.thumbTitle =
    //   "Catalog pages: <b>" + ctn + "</b>";
  }
  page.pageItem.info.sectionTitle = page.pageItem.di.Title;
  if (!page.pageItem.info.config.sectionLevel) {
    page.pageItem.info.config.sectionLevel = "";
  } else {
    if (page.pageItem.info.sectionTitle)
      page.pageItem.info.sectionTitle +=
        " (" + page.pageItem.info.config.sectionLevel + ")";
  }

  updatePage(builder, page);

  return page;
};

const getSectionName = (builder: CatalogBuilder, pageNum: number) => {
  for (let n = pageNum; n >= 0; n--) {
    if (builder.pages[n]) {
      const p = builder.pages[n].pageItem?.di;
      if (p?.PageType !== "toc" && p?.Title) return p.Title;
    }
  }
  return "";
};

const getPageArgs = (
  builder: CatalogBuilder,
  page: CatalogPageRef,
  assert?: boolean
): EvalContentArgs => {
  return {
    pageNumber: (page.viewPageId ?? page.pageId) + 1,
    sectionName: getSectionName(builder, page.pageId),
    priceField: builder.catalog.basePrice,
    assert,
  };
};

export const updatePage = (
  builder: CatalogBuilder,
  page: CatalogPageRef,
  opts?: { PageNumber?: number }
) => {
  opts = opts || {};
  const d = getDimensions(builder, page);
  const args = getPageArgs(builder, page, true);
  if (opts.PageNumber) {
    args.pageNumber = opts.PageNumber;
  }

  page.headerHeight = roundCoord(d.header * d.scaleY);
  page.footerHeight = roundCoord(d.footer * d.scaleY);
  page.pageHeight = roundCoord(templateGridSize * d.scaleY);
  page.dimensions = d;

  let firstProduct: ProductRef = null,
    lastProduct: ProductRef = null;
  if (d.header > 0 && d.footer > 0 && page.items) {
    let y1, y2, x1, x2;
    page.items.forEach((item) => {
      if (item.info && item.info.product) {
        if (
          !firstProduct ||
          item.di.y < y1 ||
          (item.di.y === y1 && item.di.x < x1)
        ) {
          firstProduct = item.info.product;
          y1 = item.di.y;
          x1 = item.di.x;
        }
        if (
          !lastProduct ||
          item.di.y > y2 ||
          (item.di.y === y2 && item.di.x > x2)
        ) {
          lastProduct = item.info.product;
          y2 = item.di.y;
          x2 = item.di.x;
        }
      }
    });
  }
  const header =
    +args.pageNumber % 2 === 0 && builder.catalog.altHeader
      ? builder.catalog.altHeader
      : builder.catalog.header;
  const footer =
    +args.pageNumber % 2 === 0 && builder.catalog.altFooter
      ? builder.catalog.altFooter
      : builder.catalog.footer;
  page.headerContent =
    d.header > 0
      ? builder.evaluator.getContent(header, firstProduct, args)
      : "";
  page.footerContent =
    d.footer > 0 ? builder.evaluator.getContent(footer, lastProduct, args) : "";

  if (page.pageItem) {
    const pc = page.pageItem.info.config;
    page.headerBkgColor = pc.headerBkgColor || builder.catalog.headerBkgColor;
    if (
      !pc.headerBkgColor &&
      builder.catalog.headerBkgColorSrc &&
      firstProduct
    ) {
      const hc = builder.evaluator.getContent(
        builder.catalog.headerBkgColorSrc,
        firstProduct,
        args
      );
      if (hc) page.headerBkgColor = hc;
    }
    page.footerBkgColor = pc.footerBkgColor || builder.catalog.footerBkgColor;
    if (
      !pc.footerBkgColor &&
      builder.catalog.footerBkgColorSrc &&
      lastProduct
    ) {
      const fc = builder.evaluator.getContent(
        builder.catalog.footerBkgColorSrc,
        lastProduct,
        args
      );
      if (fc) page.footerBkgColor = fc;
    }
    if (!page.pageItem.info.config.fontColor && builder.catalog.fontColor) {
      page.pageItem.info.config.fontColor = builder.catalog.fontColor;
    }
    if (page.pageItem.info.config.img) {
      page.pageItem.info.src = page.pageItem.info.config.img;
      page.pageItem.info.url = getImageSrc(
        builder.data.config.imageStorageUrl,
        page.pageItem.info.config.img,
        false,
        page.pageItem.version
      );
    }
  }

  page.items.forEach((item) => {
    item.di.PageId = page.pageId;
    updateItemStyle(builder, item, page.dimensions);
  });
};

export const createPage = (opts: Partial<CatalogPageRef>): CatalogPageRef => {
  return {
    pageId: 0,
    items: [],
    id: uuidv4(),
    ...opts,
  };
};

export const getPageGrid = (builder: CatalogBuilder, pageId: number) => {
  const grid = builder.grids.find((g) => pageId >= g.begin && pageId <= g.end);
  return grid;
};

export const getPageItems = (builder: CatalogBuilder, page: CatalogPageRef) => {
  if (!builder.ready) return [];

  const items = page?.items;

  return items?.filter((i) => i.di.Type !== "page") ?? [];
};

export const getListItem = (e: PageEntry) =>
  e?.readOnly ? null : e?.pages?.[0].items.find((i) => i.info?.extension);
