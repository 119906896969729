import React from "react";
import { CatalogBuilder } from "../../../features/catalogs/catalog.builder";
import { InitItemArgs } from "../../../features/catalogs/catalog.design";
import {
  initTemplateItems,
  normalizeTemplateSize,
  initTemplateItem,
  orderTemplateItem,
  removeTemplateItem,
  createTemplateItem,
} from "../../../features/catalogs/catalog.template";
import { CatalogItemRef } from "../../../features/catalogs/catalogs";
import {
  TemplateItemRef,
  TemplateItem,
} from "../../../features/design/design.types";
import { DesignBoardRef } from "../components/DesignerBoard";
import { DesignerChange, extractAndLogAction } from "../designer.board.api";
import { useDesignerHistory } from "../useHistory";
import { Template } from "../../../features/templates/templates";

export const useTemplateProcessor = (
  builder: CatalogBuilder,
  boardRef: DesignBoardRef,
  edited: CatalogItemRef,
  onChange: (persistent?: boolean, initial?: boolean) => void
) => {
  const serializeTemplate = React.useCallback(() => {
    const template = { ...edited.info.template.template };
    const items = edited.info.templateItems?.map((i) => i.di) ?? [];
    template.body = JSON.stringify(items);
    return template;
  }, [edited?.info.template.template, edited?.info.templateItems]);

  const historyApi = useDesignerHistory({
    data: edited ? serializeTemplate : null,
    onRestore: (json) => {
      if (!edited) return;
      const t = JSON.parse(json) as Template;
      edited.info.template = { template: t };
      initTemplateItems(builder, {
        parent: edited,
      });
      boardRef?.clearTargets();
      onChange(true);
    },
  });

  const initTemplateBoardItem = React.useCallback(
    (
      item: TemplateItemRef,
      partial?: Partial<TemplateItem>,
      args?: InitItemArgs
    ) => {
      if (partial) {
        const p = normalizeTemplateSize(builder, edited, partial);
        item.di = { ...item.di, ...p };
      }
      initTemplateItem(builder, item, {
        ...(args ?? {}),
        parent: edited,
      });
    },
    [builder, edited]
  );

  const processChange = React.useCallback(
    (change: DesignerChange) => {
      if (change.refreshBoard) {
        builder.refreshStructure();
      }

      //if (!change.action) return;

      if (change.clear) {
        boardRef.clearTargets();
      }

      if (change.action) onChange(true);

      const i = change.item as TemplateItemRef;
      if (change.type === "update") {
        if (change.content !== undefined) {
          const p =
            i.type === "image" && i.di.imgMode === "image"
              ? { src: change.content }
              : { text: change.content };
          initTemplateBoardItem(i, p);
        } else
          initTemplateBoardItem(i, change.data, {
            soft: change.soft,
            useOriginal: true,
          });
      } else if (change.type === "img-position") {
        initTemplateBoardItem(i, {
          position: change.item.imgPosition ?? "Fit",
        });
      }
      if (change.type === "order-up" || change.type === "order-down") {
        orderTemplateItem(edited, i, change.type === "order-up", true);
      }
      if (change.type === "remove") {
        removeTemplateItem(edited, i);
      }
      if (change.type === "create-item") {
        const item = createTemplateItem(builder, {
          ...change.attrs,
          parent: edited,
        });
        boardRef?.selectBoardItem(item, "create");
      }
      const action = extractAndLogAction("template", change);
      if (action) {
        historyApi.addHistory(change.action, 0);
      }
    },
    [boardRef, builder, edited, historyApi, initTemplateBoardItem, onChange]
  );

  return { processChange, historyApi, serializeTemplate };
};
