import React, { useState } from "react";
import { EntityType } from "../../features/data/data-types";
import { UpgradeDialog } from "./UpgradeDialog";

export const useUpgradeDialog = () => {
  const [upgradeType, setUpgradeType] = useState<EntityType>();

  const openUpgradeModal = (type: EntityType) => setUpgradeType(type);
  const renderUpgradeModal = React.useCallback(() => {
    if (!upgradeType) return null;

    return (
      <UpgradeDialog
        type={upgradeType}
        isOpen={!!setUpgradeType}
        onClose={() => setUpgradeType(null)}
      />
    );
  }, [upgradeType]);

  return { renderUpgradeModal, openUpgradeModal };
};
