import React from "react";
import { Box } from "@mui/material";
import { IconTypes } from "./icons.types";
import { CatalogExtension } from "../features/design/design.types";

const barHeight = 25;
const barMargin = 10;
const rowHeight = 10;
const rowMargin = 20;

type Props = {
  ext?: CatalogExtension;
};

export const TextPlaceholder = ({ ext }: Props) => {
  return ext ? <PlaceholderTable ext={ext} /> : <TextBoxPlaceholder />;
};

export const TextBoxPlaceholder = () => {
  const placeholderRef = React.useRef<HTMLDivElement | null>(null);
  const [barCount, setBarCount] = React.useState(0);

  React.useEffect(() => {
    const height = placeholderRef.current.offsetHeight;
    const count = Math.min(
      8,
      Math.floor((height * 0.8) / (barHeight + barMargin))
    );

    setBarCount(count);
  }, []);

  const bars = React.useMemo(
    () =>
      Array.from(
        { length: barCount },
        () => 40 + Math.floor(Math.random() * 50)
      ),
    [barCount]
  );

  return (
    <Box
      className="text-placeholder"
      ref={placeholderRef}
      sx={{ px: "10%", height: "100%", width: "100%" }}
    >
      {bars.map((width, index) => (
        <Box
          key={index}
          className="bar"
          sx={{
            mt: `${index > 0 ? barMargin : barMargin * 2}px`,
            height: `${barHeight}px`,
            width: `${width}%`,
          }}
        ></Box>
      ))}
    </Box>
  );
};

export const PlaceholderTable = ({ ext }: Props) => {
  const placeholderRef = React.useRef<HTMLDivElement>(null);
  const [rowCount, setRowCount] = React.useState<number>(0);

  React.useEffect(() => {
    if (placeholderRef.current) {
      const height = placeholderRef.current.offsetHeight;
      const count = Math.min(
        20,
        Math.floor((height * 0.6 - 20) / (rowHeight + rowMargin))
      );
      setRowCount(count);
    }
  }, []);

  const columns = React.useMemo(() => {
    return ext.fields.map((f) => {
      const fn = f.toLowerCase();
      const isShort = ["code", "price"].some((n) => fn.includes(n));
      const isLong = ["name", "description"].some((n) => fn.includes(n));
      const isImage = fn.includes("image");
      const isPrice = ["price"].some((n) => fn.includes(n));
      return {
        name: f,
        isImage,
        isPrice,
        width:
          15 +
          (isShort || isImage
            ? 0
            : (isLong ? 15 : 0) + Math.floor(Math.random() * 10)),
      };
    });
  }, [ext.fields]);

  const rowData = React.useMemo(() => {
    const w = columns.map((c) => c.width);
    return Array.from({ length: rowCount }, () => w);
  }, [columns, rowCount]);

  return (
    <Box
      className="text-placeholder"
      ref={placeholderRef}
      sx={{ px: "5%", height: "100%", width: "100%" }}
    >
      <h3>Products</h3>
      {rowData.map((row, rowIndex) => (
        <Box
          key={rowIndex}
          sx={{
            display: "flex",
            gap: 2,
            mt: `${rowMargin}px`,
            height: `${rowHeight} + px`,
          }}
        >
          {row.map((width, i) => (
            <Box
              key={i}
              className={
                rowIndex > 0 && !columns[i].isImage && !columns[i].isPrice
                  ? "bar"
                  : ""
              }
              sx={{ width: `${width}%`, height: `${barHeight}px` }}
            >
              {columns[i].isImage && rowIndex > 0 ? (
                <IconTypes.ImageNoFrame />
              ) : null}
              {columns[i].isPrice && rowIndex > 0 ? (
                <span>$ {Math.floor(Math.random() * 150)}</span>
              ) : null}
              {rowIndex === 0 && !columns[i].isImage ? columns[i].name : null}
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
};
