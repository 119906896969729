import React from "react";
import {
  PageDimensions,
  BaseCreateItemAttrs,
} from "../../features/catalogs/catalog.design";
import { CatalogPageRef } from "../../features/catalogs/catalogs";
import { DesignItem } from "../../features/design/design.types";
import {
  TargetElement,
  DesignAreaProps,
  roundCoord,
  templateGridSize,
} from "../../features/design/designer";
import { processImage } from "../../features/catalogs/catalog.util";

export const processDesignImages = () => {
  const images = Array.from(document.getElementsByClassName("content-image"));
  setTimeout(() => {
    images.forEach((img) => {
      processImage(img as HTMLImageElement, ".board-item");
    });
  }, 100);
};

export const translateElementCoordinates = (
  element: TargetElement,
  d: PageDimensions,
  board: DesignAreaProps,
  zoom: number
) => {
  d = d ?? { scaleX: 1, scaleY: 1, header: 0 };
  zoom = zoom || 1;
  const b = element.getBoundingClientRect();
  const he = element as HTMLElement;
  if (he.offsetWidth) {
    b.x = he.offsetLeft;
    b.y = he.offsetTop;
    b.width = he.offsetWidth;
    b.height = he.offsetHeight;
  }
  let l = Math.floor(b.x / zoom),
    t = Math.floor(b.y / zoom);
  const w = b.width,
    h = b.height;
  const dw = board.w - w;
  const dh = board.h - h;
  if (l > dw) {
    l = dw;
  }
  if (t > dh) {
    t = dh;
  }
  l = Math.max(l, 0);
  t = Math.max(t, 0);
  const r = +(element.dataset.rotate || 0);
  return {
    x: roundCoord(l, d.scaleX),
    y: roundCoord(t, d.scaleY),
    w: roundCoord(w, d.scaleX),
    h: roundCoord(h, d.scaleY),
    left: l,
    top: t,
    width: w,
    height: h,
    scaleX: d.scaleX,
    scaleY: d.scaleY,
    r,
  };
};

export type ElementDragProps = BaseCreateItemAttrs & {
  // type: DesignItemType;
  // subtype?: DesignSubtype;
  // contentType?: DesignItemType;
  // content?: string;
  // productId?: string;
  // templateId?: string;
  dragImgSrc?: string;
  clipboardIndex?: string;
};

export let onBoardDropGlobalHandle: () => void;

export const startElementDrag = (
  e: React.DragEvent<HTMLDivElement>,
  props: ElementDragProps,
  onDrop?: () => void
) => {
  onBoardDropGlobalHandle = onDrop;
  const designerContainer = ".designer-container";
  e.dataTransfer.setData("type", props.type);
  e.dataTransfer.setData("contentType", props.contentType ?? props.type);
  e.dataTransfer.setData("content", props.content);
  if (props.subtype) e.dataTransfer.setData("subtype", props.subtype);
  if (props.productId) e.dataTransfer.setData("productId", props.productId);
  if (props.templateId) e.dataTransfer.setData("templateId", props.templateId);
  if (props.clipboardIndex)
    e.dataTransfer.setData("clipboardIndex", props.clipboardIndex);
  if (props.dragImgSrc) {
    const img = new Image();
    img.src = props.dragImgSrc;
    e.dataTransfer.setDragImage(img, 5, 5);
  }
  e.dataTransfer.effectAllowed = "copy";
  const c = e.currentTarget.closest(designerContainer);
  c?.classList.add("dragging");
  //document.querySelector(designerContainer)?.classList.add("dragging");
  const selectors = [];
  if (props.type === "image" || props.type === "product-image") {
    selectors.push("image");
  }
  if (props.type === "product") {
    selectors.push("image", "text", "template");
  }

  if (selectors.length) {
    const query = selectors.reduce(
      (a, s) => a + (a ? ", " : "") + designerContainer + " .board-item-" + s,
      ""
    );
    const elements = document.querySelectorAll(query);
    elements.forEach((e) => {
      if (!e.classList.contains("extension")) {
        e.classList.add("element-drop-target");
      }
    });
  }
};

export const endElementDrag = () => {
  onBoardDropGlobalHandle = undefined;
  const designerContainer = ".designer-container";
  Array.from(document.querySelectorAll(designerContainer)).forEach((e) => {
    e.classList.remove("dragging");
  });
  Array.from(
    document.querySelectorAll(
      designerContainer + " .board-item.element-drop-target"
    )
  ).forEach((e) => {
    e.classList.remove("element-drop-target");
  });
  // document
  //   .querySelector(designerContainer + " .board-item.element-drop-target")
  //   ?.classList.remove("element-drop-target");
};

export const processItemImages = (el: string | HTMLElement) => {
  const target = typeof el === "string" ? document.querySelector(el) : el;
  if (!target) return;
  const images = Array.from(target.getElementsByClassName("content-image"));
  if (images.length > 0) {
    images.forEach((img) => {
      processImage(img as HTMLImageElement);
    });
  }
};

export const positionElement = (di: DesignItem, page: CatalogPageRef) => {
  if (!page.addedElements) page.addedElements = 0;
  const delta = templateGridSize / 4 + page.addedElements * 50;
  di.x = delta;
  di.y = delta;
  page.addedElements =
    di.x + di.w > templateGridSize || di.y + di.h > templateGridSize
      ? 0
      : page.addedElements + 1;
};
