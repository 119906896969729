import { AppSession, FreePlan } from "../features/accounts/account";
import { parseDate } from "../features/data/time";
import { logError, LoginTime } from "./logging";

const intercomAppId = "qb9h3t4f";

export const bootIntercom = (session?: AppSession, loginTime?: LoginTime) => {
  let userIntercomSettings = { app_id: intercomAppId, account_id: undefined };

  try {
    const accountInfo = session?.account?.isActivated
      ? {
          namespace: session.account.namespace,
          category: session.account.planCode === FreePlan ? FreePlan : "paid",
          plan: session.account.planCode,
          plan_desc: session.account.priceDescription,
          plan_gateway: session.account.gateway,
          price: session.account.monthlyPrice,
          account_status: session.account.subscriptionStatus,
          support: session.account.support,
          shop: session.account.shop,
          showrooms: session.account.summary.showrooms ?? 0,
          showroom_privacy: session.account.summary.showroomPrivacy
            ? "closed"
            : "open",
          sessions:
            session.account.summary.sessions > 0
              ? session.account.summary.sessions
              : undefined,
          products: session.account.summary.itemsCount ?? 0,
          categories: session.account.summary.categoriesCount ?? 0,
          catalogs: session.account.summary.catalogsCount ?? 0,
          users: session.account.summary.usersCount ?? 0,
          catalogs_published: session.account.summary.publishedCatalogs ?? 0,
          storage: session.account.summary.imageSizeMb ?? 0,
          ...session?.account?.summary.metaProfile.reduce((acc, cur) => {
            acc[cur.item1] = cur.item2;
            return acc;
          }, {}),
        }
      : {};

    userIntercomSettings = {
      ...userIntercomSettings,
      ...(session?.account
        ? {
            name: session.user.name,
            email: session.user.email,
            user_hash: session.user.hmac,
            created_at: parseDate(session.user.created, true).getTime() / 1000,
            user_id: "" + session?.user?.id,
            account_id: session.account.id,
            ...accountInfo,
            role: session.user.role,
            business_name: session.account.businessName,
            session_mode: session.provider === "Admin" ? "admin" : "",
            signup_source: session.account.signUpSource,
            url_ref: session.account.urlReferrer,
            use_showroom: session.account.summary.features?.showroom,
            use_orders: session.account.summary.features?.orders,

            company: {
              id: session.account.id,
              name: session.account.businessName,
              created_at:
                parseDate(session.account.created, true).getTime() / 1000,
              ...accountInfo,
            },
          }
        : {}),
    };
  } catch (e) {
    logError(e);
  }

  intercomSettings = session?.account?.isActivated ? userIntercomSettings : {};

  Intercom("boot", window.intercomSettings);

  if (!session?.account?.isActivated && session?.account?.support === "basic") {
    Intercom("update", {
      hide_default_launcher: true,
    });
  }

  if (
    session?.account?.isActivated &&
    (loginTime?.diffHours > 8 || !loginTime?.lastLoginDate)
  ) {
    Intercom("trackEvent", "new app session");
  }

  Intercom("onShow", () => {
    if (!userIntercomSettings.account_id) {
      intercomSettings = userIntercomSettings;
      Intercom("boot", userIntercomSettings);
    }
  });
};
