import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/browser";

export const initSentry = () => {
  const env = process.env.NODE_ENV;
  const options = {
    dsn: "https://00cf9894f0ed45d2b6525ff164dc0890@o43360.ingest.sentry.io/6513011",
    integrations: [
      new BrowserTracing(),
      new Sentry.Replay({
        maskAllText: false,
        maskAllInputs: false,
        minReplayDuration: 15000,
        blockAllMedia: false,
      }),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,

    release: "cm-app@" + import.meta.env.VITE_VERSION,
    enabled: env !== "development",
  };
  Sentry.init(options);
};
