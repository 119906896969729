import { useAtom } from "jotai";
import React from "react";
import { WarningDialog } from "../../../components/dialogs/WarningDialog";
import { refreshCollectionMap } from "../../../features/products/product";
import { CollectionDeleteOptions } from "../../../features/products/product-modal-state";
import { deleteCollection } from "../../../features/products/product.service";
import { productDbAtom } from "../../../features/products/product.state";
import { useDbOperations } from "../../data-func";

type Props = {
  onClose: () => void;
} & CollectionDeleteOptions;

export const CollectionDeleteModal = ({
  collectionId,
  onClose,
  onDelete,
}: Props) => {
  const [productDb, setProductDb] = useAtom(productDbAtom);
  const [processing, setProcessing] = React.useState(false);
  const { processDb } = useDbOperations();

  const col = productDb.collectionMap.get(collectionId);

  const processDeleteCollection = React.useCallback(async () => {
    if (!col) return;
    processDb(
      async () => {
        return await deleteCollection(collectionId);
      },
      () => {
        const collectionMap = new Map(productDb.collectionMap);
        collectionMap.delete(collectionId);

        const productMap = new Map(productDb.productMap);
        refreshCollectionMap(collectionMap, productMap);

        setProductDb({
          ...productDb,
          collectionMap,
          productMap,
          version: productDb.version + 1,
        });
        onDelete();
        onClose();
      },
      setProcessing,
      "Deleted collection " + col.group.name,
      "Error deleting collection."
    );
  }, [
    col,
    collectionId,
    onClose,
    onDelete,
    processDb,
    productDb,
    setProductDb,
  ]);

  if (!col) return;

  return (
    <WarningDialog
      open={!!collectionId}
      onClose={onClose}
      onConfirm={() => {
        processDeleteCollection();
      }}
      title={`Delete collection ${col?.group.name ?? ""}?`}
      action="Delete"
      color="error"
      processing={processing}
    >
      All products in this collection will be deleted everywhere including
      catalogs.
    </WarningDialog>
  );
};
