import React from "react";
import { useAtom } from "jotai";
import { useSnackbar } from "notistack";
import { WarningDialog } from "../../../components/dialogs/WarningDialog";
import { ProductDeleteOptions } from "../../../features/products/product-modal-state";
import { productDbAtom } from "../../../features/products/product.state";
import { deleteDbProducts } from "../../../features/products/product.service";
import { removeCollectionProducts } from "../../../features/products/product";

interface ProductDeleteModalProps extends ProductDeleteOptions {
  onClose: () => void;
}

export const ProductDeleteModal = (props: ProductDeleteModalProps) => {
  if (!props) {
    return null;
  }
  return <ProductDeleteModalInner {...props} />;
};

const ProductDeleteModalInner = ({
  ids,
  title,
  onDelete,
  onClose,
}: ProductDeleteModalProps) => {
  const [productDb, setProductDb] = useAtom(productDbAtom);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteProcessing, setDeleteProcessing] = React.useState(false);

  const deleteProducts = React.useCallback(
    async (ids: string[]) => {
      const existingIds = ids.filter((i) => productDb.productMap.has(i));
      if (existingIds.length > 0) {
        try {
          const result = await deleteDbProducts("products", existingIds);
          if (result.success) {
            const productMap = new Map(productDb.productMap);
            const categoryMap = new Map(productDb.categoryMap);
            const collectionMap = new Map(productDb.collectionMap);
            result.processed.forEach((id) => {
              const c = categoryMap.get(productMap.get(id)?.product.categoryId);
              if (c) {
                c.totalProductCount--;
              }
              productMap.delete(id);
            });
            removeCollectionProducts(collectionMap, existingIds);
            const db = {
              ...productDb,
              productMap,
              collectionMap,
              version: productDb.version + 1,
            };
            setProductDb(db);

            enqueueSnackbar(
              "Deleted " + result.processed.length + " product(s)",
              {
                variant: "success",
              }
            );
          } else {
            enqueueSnackbar("Error deleting products", {
              variant: "error",
            });
          }
        } catch (error) {
          enqueueSnackbar(
            "Error deleting products: " + (error as Error)?.message,
            {
              variant: "error",
            }
          );
        }
      }
    },
    [enqueueSnackbar, productDb, setProductDb]
  );

  const plural = ids.length > 1 ? "s" : "";
  return (
    <WarningDialog
      open={true}
      onClose={onClose}
      onConfirm={async () => {
        try {
          setDeleteProcessing(true);
          await deleteProducts(ids);
          onDelete?.();
        } finally {
          setDeleteProcessing(false);
          onClose();
        }
      }}
      title={`Delete ${title}?`}
      action="Delete"
      color="error"
      processing={deleteProcessing}
    >
      <ul>
        <li>The product{plural} will be deleted from all catalogs!</li>
        <li>
          Product and variant images will be deleted from the storage, other
          products and catalogs as well.
        </li>
      </ul>
    </WarningDialog>
  );
};
