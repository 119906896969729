import React from "react";
import { NavLink as RouterLink } from "react-router-dom";
import {
  Box,
  Collapse,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

interface NavItemProps {
  children?: React.ReactNode;
  className?: string;
  depth: number;
  href?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon?: any;
  title: string;
  isOpenNav: boolean;
  selected?: boolean;
  open?: boolean;
  iconHref?: string;
}

const NavItem: React.FC<NavItemProps> = ({
  children,
  depth,
  href,
  icon: Icon,
  title,
  isOpenNav,
  selected,
  open: isOpen,
  iconHref,
}: NavItemProps) => {
  const [open, setOpen] = React.useState<boolean>(isOpen);

  const style = {
    paddingLeft: isOpenNav ? "8px" : "4px",
  };

  const address = href || (isOpenNav ? "" : iconHref);

  return (
    <>
      <ListItemButton
        sx={style}
        component={RouterLink}
        to={address || "#"}
        selected={selected}
        onClick={(e) => {
          if (!address) {
            e.preventDefault();
          }
          e.stopPropagation();
          setOpen(!!address || !open);
        }}
      >
        <ListItemIcon sx={{ minWidth: 40 }}>{Icon && <Icon />}</ListItemIcon>
        {isOpenNav && <ListItemText primary={title} />}
        {isOpenNav && children && (
          <ListItemIcon
            sx={{ minWidth: 30 }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setOpen((o) => !o);
            }}
          >
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}{" "}
          </ListItemIcon>
        )}
      </ListItemButton>
      {children && (
        <Collapse in={open && isOpenNav}>
          <Box
            style={{
              paddingLeft: isOpenNav ? depth * 16 + 16 + "px" : 0,
            }}
          >
            {children}
          </Box>
        </Collapse>
      )}
    </>
  );
};

export default NavItem;
