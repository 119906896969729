import React from "react";
import {
  DesignerHistory,
  DesignerAction,
  undoAction,
  redoAction,
  addActionHistory,
  getActionHistory,
} from "../../features/catalogs/designer.history";

export interface DesignerHistoryApi {
  history: DesignerHistory;
  undo: (i: number) => void;
  redo: (i: number) => void;
  addHistory: (title: string, pageNum: number) => void;
  getHistory: () => DesignerAction[];
  clear: () => void;
}

type DesignerHistoryProps = {
  data: () => unknown;
  pageNum?: number;
  onRestore: (json: string, pageNum: number) => void;
};

export const useDesignerHistory = ({
  onRestore,
  data,
  pageNum,
}: DesignerHistoryProps): DesignerHistoryApi => {
  const [history, setHistory] = React.useState<DesignerHistory>({
    undo: [],
    redo: [],
  });
  const [initialized, setInitialized] = React.useState(false);

  const clear = React.useCallback(() => {
    setHistory({
      undo: [],
      redo: [],
    });
    setInitialized(false);
  }, []);

  const process = React.useCallback(
    (i: number, op: "undo" | "redo") => {
      const r = op === "undo" ? undoAction(history, i) : redoAction(history, i);
      if (r) {
        setHistory(r.history);
        onRestore(r.action.json, r.action.pageNum);
      }
      return r;
    },
    [history, onRestore]
  );

  const addHistory = React.useCallback(
    (title: string, pageNum: number) => {
      if (!data) return;
      const h = addActionHistory(history, title, pageNum, data());
      setHistory(h);
    },
    [data, history]
  );

  const getHistory = React.useCallback(() => {
    return getActionHistory(history);
  }, [history]);

  React.useEffect(() => {
    if (data && !initialized) {
      addHistory("start", pageNum ?? 0);
      setInitialized(true);
    }
  }, [addHistory, data, initialized, pageNum]);

  return {
    history,
    clear,
    undo: (i) => process(i, "undo"),
    redo: (i) => process(i, "redo"),
    addHistory,
    getHistory,
  };
};
