import React from "react";
import {
  Box,
  Breadcrumbs,
  Typography,
  IconButton,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Button,
  Link,
} from "@mui/material";
import { useAtom } from "jotai";
import { FixedPanel } from "../../../components/FixedPanel";
import { SearchBox } from "../../../components/SearchBox";
import { MoreButton } from "../../../components/buttons/MoreButton";
import { ProcessButton } from "../../../components/buttons/ProcessButton";
import { IconTypes } from "../../../components/icons.types";
import { EMPTY_GUID } from "../../../features/data/data-types";
import { CollectionRef } from "../../../features/products/product";
import { ProductFilter } from "../../../features/products/product-func";
import { productDbAtom } from "../../../features/products/product.state";
import { CategorySelect } from "../categories/CategorySelect";
import { ProductList } from "../list/ProductList";
import { useCollections } from "./collection-func";

type Props = {
  onSelect(collectionId?: string): void;
  onOpenEdit(
    collectionId: string,
    parentId?: string,
    withDelete?: boolean
  ): void;
  isDirty: boolean;
  setDirty: (dirty: boolean) => void;
  edit: CollectionRef;
  onUpdate: (collection: CollectionRef) => void;
};

export const CollectionEditor = ({
  onOpenEdit,
  onSelect,
  isDirty,
  setDirty,
  edit,
  onUpdate,
}: Props) => {
  const [productDb] = useAtom(productDbAtom);

  const [filter, setFilter] = React.useState<ProductFilter>({});

  const { processSaveCollection, processing } = useCollections();

  const updateItems = (ids: string[]) => {
    const c = { ...edit };
    c.group.items = ids;
    onUpdate(c);
  };

  const addProducts = (ids: string[]) => {
    edit.group.items = [...edit.group.items, ...ids];
    onUpdate({ ...edit });
  };

  const removeProduct = (id: string) => {
    edit.group.items = edit.group.items.filter((i) => i !== id);
    onUpdate({ ...edit });
  };

  const clearProducts = () => {
    edit.group.items = [];
    onUpdate({ ...edit });
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
        className="collection-header"
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            pl: 1,
          }}
        >
          <Breadcrumbs aria-label="breadcrumb">
            {edit?.parents.map((p) => (
              <Link
                key={p.group.id}
                underline="hover"
                color="inherit"
                onClick={() => {
                  onSelect(p.group.id);
                }}
                sx={{ cursor: "pointer" }}
              >
                {p.group.name}
              </Link>
            ))}

            <Typography color="text.primary">{edit?.group.name}</Typography>
          </Breadcrumbs>

          <IconButton
            sx={{ ml: 2 }}
            onClick={() => onOpenEdit(edit?.group.id)}
            size="small"
          >
            <IconTypes.Edit fontSize="small" />
          </IconButton>
          <MoreButton
            color="inherit"
            menu={(onClose) => [
              <MenuItem
                key="clear"
                onClick={() => {
                  clearProducts();
                  onClose();
                }}
              >
                <ListItemIcon>
                  <IconTypes.Clear fontSize="small" />
                </ListItemIcon>
                <ListItemText>Remove all products</ListItemText>
              </MenuItem>,
              <MenuItem
                key="sub"
                onClick={() => {
                  onOpenEdit(EMPTY_GUID, edit?.group.id);
                  onClose();
                }}
              >
                <ListItemIcon>
                  <IconTypes.Add fontSize="small" />
                </ListItemIcon>
                <ListItemText>Add sub collection</ListItemText>
              </MenuItem>,
              <MenuItem
                key="del"
                onClick={() => {
                  onOpenEdit(edit?.group.id, null, true);
                  onClose();
                }}
              >
                <ListItemIcon>
                  <IconTypes.DeleteAction color="error" fontSize="small" />
                </ListItemIcon>
                <ListItemText>Delete collection</ListItemText>
              </MenuItem>,
            ]}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            minHeight: 50,
            flexWrap: "wrap",
          }}
        >
          {isDirty && (
            <>
              <Button
                size="small"
                onClick={() => {
                  const c = productDb.collectionMap.get(edit?.group.id);
                  onUpdate({ ...c });
                  setDirty(false);
                }}
              >
                Reset
              </Button>
              <ProcessButton
                processing={processing}
                onClick={() =>
                  processSaveCollection(edit.group, true, (id) => {
                    onSelect(id);
                    setDirty(false);
                  })
                }
                label="Save"
                size="small"
              />
            </>
          )}
          <Box
            sx={{
              display: "flex",
              width: {
                xs: "100%",
                sm: "auto",
              },
            }}
          >
            <SearchBox
              value={filter.search ?? ""}
              onChange={(search) => setFilter((f) => ({ ...f, search }))}
              placeholder="Search products…"
            />
            <CategorySelect
              sx={{ ml: 1 }}
              categoryId={filter.categoryId}
              onChange={(categoryId) =>
                setFilter((f) => ({ ...f, categoryId }))
              }
              defaultLabel="--All categories--"
            />
          </Box>
        </Box>
      </Box>
      <Box display="flex">
        <Box
          sx={{
            flex: 1,
            px: 1,
          }}
        >
          <FixedPanel overflow="auto" margin={5} breakpoint="md">
            <Box sx={{ height: "100%", display: "flex", gap: 1 }}>
              {productDb.productMap.size > 0 && (
                <ProductList
                  productIds={edit?.group.items}
                  actions={["remove", "sort"]}
                  onAction={(a, ids) => {
                    if (a === "remove") removeProduct(ids[0]);
                    else updateItems(ids);
                  }}
                  fixed={true}
                />
              )}
              <ProductList
                excludeIds={edit?.group.items}
                actions={["add"]}
                onAction={(_, ids) => addProducts(ids)}
                filter={filter}
                fields={["Modified"]}
              />
            </Box>
          </FixedPanel>
        </Box>
      </Box>
    </Box>
  );
};
