import { Box, Button } from "@mui/material";
import { IconTypes } from "../../../components/icons.types";
import { SearchBox } from "../../../components/SearchBox";
import { SelectFromList } from "../../../components/SelectFromList";
import { EMPTY_GUID } from "../../../features/data/data-types";
import { TemplateViewOptions } from "./TemplateList";
import { templateSortBy } from "./template.render";
import useIsMobile from "../../../ui/useIsMobile";

type TemplateListFilterProps = {
  view?: TemplateViewOptions;
  setView: (view: TemplateViewOptions) => void;
  templateId?: string;
  onAddTemplate: () => void;
};

export const TemplateListFilter = ({
  view,
  setView,
  templateId,
  onAddTemplate,
}: TemplateListFilterProps) => {
  const isMobile = useIsMobile("md");

  return (
    <Box
      sx={{
        display: "flex",
        p: 1,
        flexWrap: "wrap",
        gap: 1,
        minHeight: 56,
      }}
    >
      <SearchBox
        value={view.filter ?? ""}
        onChange={(filter) => setView({ ...view, filter })}
        placeholder="Search templates…"
        size="mini"
      />
      <SelectFromList
        entries={templateSortBy}
        value={view.sort}
        setValue={(v) => {
          setView({ ...view, sort: v });
        }}
        label="Sort By"
        size="mini"
        sx={{ ml: 1, minWidth: 80 }}
      />
      <div>
        <Button
          size="small"
          variant="outlined"
          title="Add new Card"
          onClick={onAddTemplate}
          sx={{
            minWidth: isMobile ? 30 : 50,
            padding: isMobile ? "5px" : "5px 8px",
            fontSize: 12,
          }}
          disabled={templateId === EMPTY_GUID}
          startIcon={isMobile ? undefined : <IconTypes.Add />}
        >
          {isMobile ? <IconTypes.Add fontSize="small" /> : "New template"}
        </Button>
      </div>
    </Box>
  );
};
