import { MenuItem, SxProps } from "@mui/material";
import { SelectFromList } from "../../../components/SelectFromList";
import { useDialogs } from "../../../contexts/useDialogs";
import { useProducts } from "../../../features/products/product-func";

export const CollectionSelect = ({
  collectionId,
  onChange,
  defaultLabel,
  sx,
}: {
  collectionId: string;
  onChange: (collectionId: string) => void;
  defaultLabel?: string;
  sx?: SxProps;
}) => {
  const { productModalApi } = useDialogs();
  const { collectionNames } = useProducts();

  return (
    <SelectFromList
      entries={collectionNames.map((c) => ({
        value: c.value,
        name: `${c.name} (${c.count})`,
        level: c.level,
      }))}
      value={collectionId ?? ""}
      setValue={(v) => {
        if (v === "new") {
          productModalApi.openCollectionAttrs({
            withItems: true,
            onChange,
          });
        } else {
          onChange(v);
        }
      }}
      defaultLabel={defaultLabel}
      label={collectionId ? "Collection" : ""}
      size="small"
      sx={sx}
    >
      <MenuItem value="new">+ Create new collection</MenuItem>
    </SelectFromList>
  );
};
