import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  TextField,
} from "@mui/material";
import {
  DataGridPro,
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  //useGridApiRef,
} from "@mui/x-data-grid-pro";
import { useAtom } from "jotai";
import { ProcessButton } from "../../../components/buttons/ProcessButton";
import {
  ProductOption,
  refreshProductOptions,
} from "../../../features/products/product";
import {
  saveOption,
  deleteOption,
} from "../../../features/products/product.service";
import { productDbAtom } from "../../../features/products/product.state";
import { useDbOperations } from "../../data-func";
import { IconTypes } from "../../../components/icons.types";

interface OptionAddDialogProps {
  open: boolean;
  options: number[];
  categoryName: string;
  onToggle: (optionId: number) => void;
  onClose: () => void;
}

export const OptionAddDialog = ({
  open,
  categoryName,
  options,
  onToggle,
  onClose,
}: OptionAddDialogProps) => {
  //const apiOptionRef = useGridApiRef();
  const [productDb, setProductDb] = useAtom(productDbAtom);
  const [newOption, setNewOption] = React.useState<string>("");
  const [processing, setProcessing] = React.useState(false);

  const { processDb } = useDbOperations();

  const processSaveOption = React.useCallback(
    async (opt: ProductOption) => {
      const result = await processDb(
        async () => {
          return await saveOption(opt);
        },
        (result) => {
          setNewOption("");
          const optionMap = new Map(productDb.optionMap);
          optionMap.set(result.value.optionId, result.value);
          if (opt.optionId > 0) {
            refreshProductOptions(productDb.productMap, optionMap);
          }
          setProductDb({
            ...productDb,
            optionMap,
            version: productDb.version + 1,
          });
          if (!opt.optionId) {
            onToggle(result.value.optionId);
          }
        },
        setProcessing,
        "Saved option " + opt.name,
        "Error saving option."
      );
      return result?.success;
    },
    [onToggle, processDb, productDb, setProductDb]
  );

  const processDeleteOption = React.useCallback(
    async (opt: ProductOption) => {
      const result = await processDb(
        async () => {
          return await deleteOption(opt.optionId);
        },
        () => {
          if (options.indexOf(opt.optionId) >= 0) onToggle(opt.optionId);
          const optionMap = new Map(productDb.optionMap);
          optionMap.delete(opt.optionId);
          refreshProductOptions(productDb.productMap, optionMap);
          setProductDb({
            ...productDb,
            optionMap,
            version: productDb.version + 1,
          });
        },
        setProcessing,
        "Deleted option " + opt.name,
        "Error deleting option."
      );
      return result?.success;
    },
    [onToggle, options, processDb, productDb, setProductDb]
  );

  const optionColumns: GridColDef[] = React.useMemo(
    () => [
      {
        field: "add",
        headerName: "Add / Remove",
        width: 120,
        renderCell: (params: GridRenderCellParams) => {
          const id = +params.id;
          const isAdded = options.indexOf(id) >= 0;
          return (
            <Button
              variant="outlined"
              size="small"
              color={isAdded ? "error" : "success"}
              onClick={() => {
                onToggle(id);
              }}
            >
              {isAdded ? "- Remove" : " + Add"}
            </Button>
          );
        },
      },
      {
        field: "name",
        headerName: "Option",
        flex: 1,
        minWidth: 100,
        editable: true,
      },
      {
        field: "count",
        headerName: "Used",
      },
      {
        field: "actions",
        type: "actions",
        width: 35,
        getActions: (params) => {
          const opt = productDb.optionMap.get(+params.id);
          return opt && !opt.count
            ? [
                <GridActionsCellItem
                  icon={<IconTypes.DeleteAction />}
                  label="Delete option"
                  key="1"
                  onClick={() => {
                    processDeleteOption(opt);
                  }}
                />,
              ]
            : [];
        },
      },
    ],
    [onToggle, options, processDeleteOption, productDb.optionMap]
  );

  const optionRows = React.useMemo(() => {
    return Array.from(productDb.optionMap.values())
      .map((opt) => ({
        id: opt.optionId,
        ...opt,
      }))
      .sortList((o) => o.name.toLowerCase());
  }, [productDb.optionMap]);

  if (!open) return null;

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>
        <div>Add / remove options for {categoryName} category</div>
      </DialogTitle>
      <DialogContent sx={{ minHeight: 100 }}>
        <Box sx={{ my: 1 }}>
          <TextField
            label="New Option Name"
            size="small"
            value={newOption}
            onChange={(e) => setNewOption(e.target.value)}
            InputProps={{
              endAdornment: (
                <ProcessButton
                  label="Add"
                  disabled={!newOption}
                  processing={processing}
                  size="small"
                  onClick={() => {
                    processSaveOption({ optionId: 0, name: newOption });
                  }}
                />
              ),
            }}
          />
        </Box>
        <DataGridPro
          //apiRef={apiOptionRef}
          rows={optionRows}
          columns={optionColumns}
          processRowUpdate={(newRow, oldRow) => {
            if (
              processSaveOption({
                name: newRow.name,
                optionId: newRow.optionId,
              })
            )
              return newRow;
            else return oldRow;
          }}
          autoHeight
          hideFooter
          //experimentalFeatures={{ newEditingApi: true }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Apply changes</Button>
      </DialogActions>
    </Dialog>
  );
};
