import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  DialogTitle,
  Box,
  Typography,
  IconButton,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { grey } from "@mui/material/colors";
import { ImageModal, ImageModalParams, ImageModalType } from "./ImageModal";
import { useAtom } from "jotai";
import { fetchFileType, downloadFile } from "../../../features/data/file-util";
import { formatDateStr } from "../../../features/data/time";
import {
  getImageUrl,
  ImageSelectOptions,
} from "../../../features/images/image";
import { useImages } from "../../../features/images/image-func";
import { imageDbAtom } from "../../../features/images/image.state";

// export interface ImageViewParams {
//   open: boolean;
//   path?: string;
//   onUpload: (path: string) => void;
//   onClose: () => void;
//   openFileModal: (opts: ImageSelectOptions) => void;
// }

export const ImageViewModal = (params: ImageModalParams) => {
  return (
    <ImageModal
      open={!!params.options}
      title={params.options?.path}
      onClose={params.onClose}
    >
      <ImageViewContent {...params} />
    </ImageModal>
  );
};

export const ImageViewContent = ({
  options,
  onClose,
  openModal,
}: ImageModalParams) => {
  const [imgDimensions, setImageDimensions] = React.useState<{
    w: number;
    h: number;
  }>();
  const [imageDb] = useAtom(imageDbAtom);
  const [imgType, setImgType] = useState<string>();

  const { openImageEditor } = useImages();

  const onCloseView = useCallback(() => {
    setImageDimensions(null);
    onClose();
  }, [onClose]);

  const path = options?.path;
  const image = path ? imageDb.imageMap.get(path) : null;

  const imgUrl = useMemo(
    () => getImageUrl(imageDb.storageUrl, path),
    [imageDb.storageUrl, path]
  );

  const openImageModal = useCallback(
    (type: ImageModalType, override?: ImageSelectOptions) => {
      onCloseView();
      const opts = override ? { ...options, ...override } : options;
      openModal(opts, type);
    },
    [onCloseView, openModal, options]
  );

  useEffect(() => {
    const getType = async () => {
      const type = await fetchFileType(imgUrl, path);
      setImgType(type);
    };
    if (!path) {
      openImageModal("upload");
    } else {
      getType();
    }
  }, [imgUrl, openImageModal, path]);

  return (
    <>
      {options && (
        <>
          <DialogTitle>
            <Box sx={{ mr: "30px" }}>
              {path}
              <Typography variant="caption" component="div">
                <Box
                  sx={{
                    display: "flex",
                    "& .date": { ml: 2 },
                    "& .size": { mr: 1, fontWeight: "bold" },
                  }}
                >
                  <div className="info">
                    <span className="size">
                      {image?.size > 0
                        ? (image.size / 1024).toFixed(0) + " KB"
                        : "N/A "}
                    </span>
                    <span className="ext">{imgType}</span>
                    <span className="date">
                      {formatDateStr(image?.modified)}
                    </span>
                  </div>

                  {imgDimensions && (
                    <Box sx={{ ml: "auto" }}>
                      dimensions:{" "}
                      <b>
                        {imgDimensions.w} x {imgDimensions.h}
                      </b>
                    </Box>
                  )}
                </Box>
              </Typography>
            </Box>
            <IconButton
              aria-label="close"
              onClick={onCloseView}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box
              sx={{
                display: "flex",
                "& img": { maxWidth: "100%", maxHeight: "70vh", m: "auto" },
              }}
            >
              <img
                src={imgUrl}
                alt=""
                onLoad={(e) => {
                  const img = e.target as HTMLImageElement;
                  if (img) {
                    setImageDimensions({
                      w: img.naturalWidth ?? img.width,
                      h: img.naturalHeight ?? img.height,
                    });
                  }
                }}
                onError={(e) => {
                  const img = e.target as HTMLImageElement;
                  img.alt = " Load error";
                }}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            {options.canChange && (
              <Button onClick={() => openImageModal("select")}>
                Select another
              </Button>
            )}
            <Button
              onClick={() =>
                openImageModal("upload", { canSelectFolder: false })
              }
            >
              Re-upload
            </Button>
            <Button
              onClick={() => {
                onCloseView();
                openImageEditor(path, options.onSelect);
              }}
            >
              Edit
            </Button>
            <Button
              onClick={() => {
                downloadFile(image.url, image.path);
              }}
            >
              Download
            </Button>
          </DialogActions>
        </>
      )}
    </>
  );
};
