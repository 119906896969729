import React, { useState, useEffect } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogContent,
  DialogActions,
  Box,
  FormGroup,
  FormLabel,
  FormHelperText,
} from "@mui/material";
import Cookies from "js-cookie";
import { CONSENT_COOKIE_NAME, CookiePreferences } from "../../services/consent";

//const isDev = process.env.NODE_ENV == "development";

const CookieConsentDialog = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [manage, setManage] = useState<boolean>(false);
  const [preferences, setPreferences] = useState<CookiePreferences>({
    essential: true,
    analytics: false,
  });

  useEffect(() => {
    const cookieConsent = Cookies.get(CONSENT_COOKIE_NAME);
    if (!cookieConsent) {
      const legacyCookieConsent = Cookies.get("cookieConsent");
      if (legacyCookieConsent) {
        setCookieConsent(JSON.parse(legacyCookieConsent));
      } else {
        setOpen(true);
      }
    } else {
      const consentPreferences: CookiePreferences = JSON.parse(cookieConsent);
      setPreferences(consentPreferences);
    }
  }, []);

  const setCookieConsent = (consent: CookiePreferences) => {
    Cookies.set(CONSENT_COOKIE_NAME, JSON.stringify(consent), {
      expires: 365,
      sameSite: "Lax",
      //domain: isDev ? undefined : ".catalogmachine.com",
    });
    setOpen(false);
  };

  const handleAcceptAll = () => {
    setCookieConsent({
      essential: true,
      analytics: true,
    });
  };

  const handleSavePreferences = () => {
    setCookieConsent(preferences);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPreferences({
      ...preferences,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="cookie-consent-dialog-title"
    >
      <DialogContent>
        <p>
          We use cookies to enhance your experience and analyze our
          website&apos;s traffic. For more information, please visit our{" "}
          <a
            href="https://www.catalogmachine.com/privacy"
            style={{ color: "#0056b3" }}
          >
            privacy policy
          </a>
          .
        </p>
        {manage && (
          <Box>
            <FormLabel component="legend">
              Please select the cookie categories you wish to accept.
            </FormLabel>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={preferences.essential} disabled />}
                label="Essential Cookies"
              />
              <FormHelperText>
                Necessary for the website to function and cannot be switched
                off.
              </FormHelperText>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={preferences.analytics}
                    onChange={handleChange}
                    name="analytics"
                  />
                }
                label="Analytics & Performance Cookies"
              />
              <FormHelperText>
                Allow us to measure and improve the performance of our site.
              </FormHelperText>
            </FormGroup>
          </Box>
        )}
      </DialogContent>
      <DialogActions sx={{ p: 1 }}>
        <Button variant="contained" onClick={handleAcceptAll} color="secondary">
          Accept All Cookies
        </Button>
        {!manage && (
          <Button onClick={() => setManage(true)} color="secondary">
            Manage Cookies
          </Button>
        )}
        {manage && (
          <Button
            variant="outlined"
            onClick={handleSavePreferences}
            color="secondary"
          >
            Save Preferences
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CookieConsentDialog;
